@charset "UTF-8";

/*!共通*/

html {
  margin: 0 !important;
}

body {
  width: 100%;
  padding: 0;
  margin: 0;
  font-family: 'YuGothic', 'Yu Gothic','游ゴシック','ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro','メイリオ','Meiryo',sans-serif;
  font-size: 14px;
  color: #231815;
  -webkit-font-smoothing: subpixel-antialiased !important;
  font-feature-settings: "palt";
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
  border: 0;
}

ul, ol {
  list-style: none;
  font-size: 0;
}

h1, h2, h3, h4, ul, dl, dd, p, hr, figure {
  margin: 0;
  padding: 0;
}

img {
  vertical-align: bottom;
  border: 0;
}

.inner {
  width: 1000px;
  margin: 0 auto;
  position: relative;
}

.pc {
  display: block !important;
}

.sp {
  display: none !important;
}

/* !!------------------------------------ */
/* !! はらべ鍼灸整体院の根本改善とは */

div.movie_area {
	margin-top: 30px;
	background: #eeeceb;
	padding-bottom: 50px;
	position: relative;
	span {
		font-size: 14px;
		line-height: 30px;
		color: #fff;
		padding-right: 20px;
		text-align: right;
		background: #5c070c;
		display: block;
	}
	h2 {
		font-size: 24px;
		color: #5c070c;
		text-align: center !important;
		line-height: 100%;
		padding: 30px 0;
		&:before {
			content: "";
			width: 84px;
			height: 40px;
			display: block;
			background: url(/images/movie_icon.png) no-repeat center center;
			background-size: 84px 40px;
			position: absolute;
			top: 20px;
			left: 20px;
		}
	}
	iframe {
		display: block;
		margin: 0 auto;
	}
}

.line_bana {
	display: block;
	margin: 30px auto 0 auto;
	img {
		width: 100%;
		height: auto;
	}
}

/* !!------------------------------------ */
/* !! 画像の後読み */

*[data-src]{
	opacity: 0;
	transition: 500ms;
	&.on{
		opacity: 1;
	}
}


/*!header*/

div {
  &#header {
    width: 100%;
    height: 230px;
    background: url(../images/header_bg) repeat-x;
    background: -moz-linear-gradient(50% 0% -90deg, rgba(93, 7, 12, 1) 0%, rgba(0, 0, 0, 0.9) 100%);
    background: -webkit-linear-gradient(-90deg, rgba(93, 7, 12, 1) 0%, rgba(0, 0, 0, 0.9) 100%);
    background: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0, rgba(93, 7, 12, 1)), color-stop(1, rgba(0, 0, 0, 0.9)));
    background: -o-linear-gradient(-90deg, rgba(93, 7, 12, 1) 0%, rgba(0, 0, 0, 0.9) 100%);
    background: -ms-linear-gradient(-90deg, rgba(93, 7, 12, 1) 0%, rgba(0, 0, 0, 0.9) 100%);
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#5D070C', endColorstr='#000000' ,GradientType=0)";
    background: linear-gradient(180deg, rgba(93, 7, 12, 1) 0%, rgba(0, 0, 0, 0.9) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#5D070C',endColorstr='#000000' , GradientType=0);
    color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    &.fix {
      position: fixed;
      top: -110px;
      z-index: 10000;
    }
    div.inner {
      width: 1000px !important;
      height: auto !important;
      padding-top: 25px;
    }
    h1 {
	    position: static !important;
    }
    a {
      &#logo {
        width: 246px;
        height: 80px;
        display: block;
      }
      &#tel {
        width: 294px;
        height: 36px;
        display: block;
        position: absolute;
        top: 32px;
        left: 290px;
      }
    }
    div {
      &.sp_bt, &.sp_tel {
        display: none !important;
      }
      &.consul {
        position: absolute;
        top: 85px;
        left: 290px;
        p {
          font-size: 13px;
          line-height: 22px;
          display: inline-block;
          br {
            display: none;
          }
          &:before {
            width: 60px;
            text-align: center;
            border: 1px solid #fff;
            display: inline-block;
            line-height: 20px;
            margin-right: 0.5em;
          }
          &.time:before {
            content: "受付時間";
          }
          &.week:before {
            content: "休診日";
            margin-left: 0.5em;
          }
        }
      }
    }
    ul.list_menu {
      font-size: 0;
      position: absolute;
      top: 20px;
      right: 0;
      li {
        display: inline-block;
        font-size: 12px;
        color: #fff;
        line-height: 100%;
        border-left: 1px solid #fff;
        padding: 0 0.5em;
        &.last {
          border-right: 1px solid #fff;
        }
      }
    }
  }
  &.sns {
    position: absolute;
    top: 40px;
    right: 0;
    font-size: 0;
    a {
      &#twitter, &#facebook {
        width: 37px;
        height: 37px;
        display: inline-block;
      }
      &#twitter {
        margin-right: 10px;
      }
      &:hover {
        opacity: 0.5;
        transition: 0.7s;
      }
    }
  }
  &#header ul.nav {
    width: 1000px;
    height: 80px;
    text-align: center;
    display: table;
    table-layout: fixed;
    margin: 25px auto 0 auto;
    li {
      height: 80px;
      display: table-cell;
      vertical-align: middle;
      font-size: 16px;
      font-weight: bold;
      border-left: 1px solid rgba(120, 52, 38, 0.8);
      &.last {
        border-right: 1px solid rgba(120, 52, 38, 0.8);
      }
      a {
        display: block;
        height: 80px;
        &:hover {
          transition: 0.7s;
          background: rgba(255, 255, 255, 0.3);
        }
      }
      img {
        display: block;
        margin: 0 auto 10px auto;
        padding-top: 7px;
      }
    }
  }
  &#container {
    &.home {
      height: 650px;
      width: 100%;
      /*! background: url(../images/main_img.jpg) no-repeat center top; */
      background-size: cover;
      position: relative;
      overflow: hidden;
    }
    &.price {
      height: 500px;
      background: url(../images/price.jpg) no-repeat center center;
      background-size: cover;
      position: relative;
    }
    &.profile {
      height: 500px;
      background: url(../images/profile.jpg) no-repeat center top;
      background-size: cover;
      position: relative;
    }
    &.readme {
      height: 500px;
      background: url(../images/readme.jpg) no-repeat center center;
      background-size: cover;
      position: relative;
    }
    &.question {
      height: 500px;
      background: url(../images/question.jpg) no-repeat center center;
      background-size: cover;
      position: relative;
    }
    &.access {
      height: 500px;
      background: url(../images/access.jpg) no-repeat center top;
      background-size: cover;
      position: relative;
    }
    &.flow {
      height: 500px;
      background: url(../images/flow.jpg) no-repeat center center;
      background-size: cover;
      position: relative;
    }
    &.voice {
      height: 500px;
      background: url(../images/voice.jpg) no-repeat center center;
      background-size: cover;
      position: relative;
    }
    &.contact {
      height: 500px;
      background: url(../images/contact.jpg) no-repeat center center;
      background-size: cover;
      position: relative;
    }
    &.shourei {
      height: 230px;
    }
    &.privacy {
      height: 500px;
      background: url(../images/privacy.jpg) no-repeat center center;
      background-size: cover;
      position: relative;
    }
    &.sitemap {
      height: 500px;
      background: url(../images/sitemap.jpg) no-repeat center center;
      background-size: cover;
      position: relative;
    }
    &.jiko {
      height: 500px;
      background: url(../images/jiko.jpg) no-repeat center center;
      background-size: cover;
      position: relative;
    }
    &.nail {
      height: 500px;
      background: url(../images/nail.jpg) no-repeat center center;
      background-size: cover;
      position: relative;
    }
    &.blog {
      height: 230px;
    }
    &.home {
      h1 {
        background: none !important;
        padding: 0 !important;
        position: absolute;
        bottom: 20px;
      }
      div.main.inner {
        width: 1000px;
        height: 420px;
        position: absolute;
        bottom: 0;
        left: 50%;
        z-index: 1000;
        margin-left: -500px;
      }
    }
    div.inner {
      width: 100%;
      height: 500px;
      h1 {
        position: absolute;
        bottom: 160px;
      }
      .point {
	      position: absolute;
	      font-size: 0;
	      bottom: 30px;
	      li {
		      display: inline-block;
		      margin-right: 10px;
		      &:last-child {
			      margin-right: 0;
		      }
	      }
      }
      .catch {
	      position: absolute;
	      right: 0;
	      &.top {
		      bottom: 175px;
	      }
	      &.bottom {
		      bottom: 30px;
	      }
      }
    }
  }
  &#wrapper {
    width: 730px;
    //min-height: 3901px !important;
    margin: 30px 0 50px 270px;
    position: relative;
    a {
      &.campaign {
        display: block;
        margin-bottom: 10px;
      }
      &.ekiten {
        display: block;
        margin-bottom: 30px;
      }
      &.campaign:hover, &.ekiten:hover {
        transition: 0.7s;
        opacity: 0.5;
      }
    }
    div {
      &.voice_pickup {
        h2 {
          text-align: right;
          &.interview {
            font-size: 24px;
            color: #5c070c;
            text-align: center;
            line-height: 100%;
            margin-top: 30px;
            margin-bottom: 10px;
          }
        }
        p {
          font-size: 14px;
          line-height: 24px;
          text-align: center;
          font-weight: bold;
        }
      }
      &.section01, &.voice_pickup {
        position: relative;
        margin: 30px 0;
      }
      &.section01 h2 {
        text-align: center;
      }
    }
    dl.slidelist h2 {
      text-align: center;
    }
    div {
      &.section01 {
        h2 span {
          display: block;
          line-height: 0;
          margin-top: 0.5em;
        }
        ul {
          width: 460px;
          height: 320px;
          overflow: hidden;
          margin: 30px 0;
          li {
            font-size: 14px;
            font-weight: bold;
            height: 40px;
            line-height: 40px;
            background: url(../images/section_icon.gif) no-repeat left center;
            padding-left: 30px;
            border-top: 2px dotted #9e9e9f;
            &.last {
              height: auto;
              background: none;
              padding-left: 0;
              h3 {
                margin: 14px 0 0 0;
                padding: 12px 0;
                background: #5c070c;
                color: #fff;
                font-size: 20px;
                font-weight: bold;
                text-align: center;
                line-height: 100%;
                span {
                  font-size: 14px;
                  display: block;
                }
              }
            }
          }
			&.case {
				width: auto;
				height: auto;
				margin: 30px auto 0 auto;
				font-size: 0;
				&:after {
					content: "";
					width: 0;
					height: 0;
					display: block;
					margin: 30px auto;
					border-style: solid;
					border-width: 30px 45px 0 45px;
					border-color: #e5cfd1 transparent transparent transparent;
				}
				li {
				  width: calc((100% - 40px)/3);
				  height: 200px;
				  line-height: 1;
				  background: #bedfc2;
				  padding-left: 0;
				  border-top: none;
				  border: 1px solid #9fa0a0;
				  border-radius: 10px;
				  display: inline-block;
				  vertical-align: top;
				  margin: 0 20px 20px 0;
				  position: relative;
				  &:nth-child(3n) , &:last-child {
				      margin-right: 0;
				  }
				  &:nth-child(n + 4) {
					  margin-bottom: 0;
				  }
				  a {
					  width: 100%;
					  height: 100%;
					  display: block;
				  }
				  img {
					  position: absolute;
					  top: 50%;
					  left: 50%;
					  transform: translate(-50%,-50%);
					  -webkit-transform: translate(-50%,-50%);
					  -moz-transform: translate(-50%,-50%);
					  -o-transform: translate(-50%,-50%);
					  -ms-transform: translate(-50%,-50%);
				  }
				}
				+h3 {
					font-size: 32px;
					line-height: 44px;
					color: #5c070c;
					text-align: center;
					strong {
						background: linear-gradient(transparent 50%, yellow 50%);
					}
				}
			}
        }
      }
      &.imgposi01 {
        position: absolute;
        top: 119px;
        right: 0;
      }
    }
    hr {
      border-top: 2px dotted #9e9e9f;
      border-bottom: none;
    }
	.amazon_ss {
		padding: 20px 0;
		text-align: center;
		color: #fff;
		background: #5c070c;
		margin: 30px 0 20px 0;
	}
    .ebook {
	    width: 100%;
	    height: auto;
	    display: block;
	    margin-top: 30px;
	    border: 1px solid #231815;
	    img {
		    width: 100%;
		    height: auto;
	    }
	    &:hover {
		    opacity: 0.7;
	    }
    }
    div {
      &.section01 div.greetingbox01 {
        margin: 25px 0 30px 0;
        position: relative;
        p {
          margin-left: 260px;
          line-height: 30px;
        }
      }
      &.greetingbox01 img {
        position: absolute;
        top: 0;
        left: 0;
        margin-right: 20px;
      }
      &.section01 div.greetingbox02 {
        margin: 0 0 30px 0;
        position: relative;
      }
      &.greetingbox02 {
        img {
          position: absolute;
          top: 0;
          right: 0;
          margin-left: 20px;
        }
        p {
          width: 470px;
          line-height: 30px;
        }
        span {
          display: block;
          margin: 2em 0 0 0;
          text-align: center;
          line-height: 25px;
        }
      }
      &.media {
        margin: 30px 0 0 0;
        background: #eeeceb;
        div.profile_photo {
          margin-bottom: 0;
          padding-bottom: 0;
          ul {
            margin: 20px 60px;
          }
          hr {
            width: 100%;
          }
          ul {
            &.award {
              width: 670px;
              margin: 20px 0;
            }
            &.recommend {
              width: 670px;
              margin: 20px 0;
              li {
                width: 335px;
              }
            }
          }
        }
        h2 {
	        &.pc {
		        span {
			        margin-bottom: 0;
			        line-height: 20px;
		        }
		        strong {
			        display: block;
			        line-height: 1;
			        margin-top: 10px;
		        }
	        }
	        &.sp {
              text-align: center;
              color: #fff;
              background: #5c070c;
              font-size: 30px;
              line-height: 22px;
              font-weight: bold;
              padding: 15px 0;
              margin: 30px 0;
	        }
        }
      }
      &.media_bana h2 {
	      height: 140px;
	      background: url(/images/media_bg.jpg) no-repeat center center;
	      background-size: 730px 140px;
	      font-weight: normal;
	      margin-bottom: 15px;
	      position: relative;
	      span {
		      width: 100%;
		      font-size: 30px;
		      font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
		      line-height: 44px;
		      color: #fff;
		      position: absolute;
		      top: 50%;
		      left: 50%;
		      transform: translate(-50%,-50%);
		      -webkit-transform: translate(-50%,-50%);
		      -moz-transform: translate(-50%,-50%);
		      -o-transform: translate(-50%,-50%);
		      -ms-transform: translate(-50%,-50%);
		      text-align: center;
		      margin-top: 0;
	      }
	      strong {
		      font-size: 40px;
		      color: #d3cea3;
	      }
      }
      &.media {
	    .media_bana {
		    margin-bottom: 30px;
	    }
        h2 span {
          color: #fff;
          font-size: 14px;
          line-height: 18px;
          display: block;
          margin-bottom: 6px;
        }
        ul.movie {
          width: 460px;
          height: auto;
          margin: 20px auto 5px auto;
          li {
            margin-right: 20px;
            display: inline-block;
            border-top: none;
            background: none;
            padding-left: 0;
            &.last {
              margin-right: 0;
            }
          }
        }
        div.radio {
          margin: 0 auto;
          position: relative;
          p {
            position: absolute;
            top: 90px;
            left: 100px;
          }
          audio {
            width: 460px;
            height: 200px;
            background: url(../images/fm_hirakata.jpg) no-repeat top center;
            background-size: 460px 200px;
            margin: 20px auto 5px auto;
            display: block;
          }
        }
        p.radio {
          position: static;
          text-align: center;
          font-size: 13px;
          line-height: 20px;
          margin-bottom: 20px;
          a {
            display: inline-block;
            text-align: center;
            text-decoration: underline;
            font-weight: bold;
            color: #a70d23;
            &:hover {
              text-decoration: none;
            }
          }
        }
        div {
          &.radio {
            p.radio {
              width: 460px;
              position: static;
              text-align: center;
              font-size: 13px;
              line-height: 20px;
              margin: 0 auto;
              span {
                display: block;
                font-size: 12px;
                text-align: center;
                border: 1px solid #666;
                color: #666;
                margin-bottom: 5px;
              }
            }
            a {
              display: inline-block;
              text-align: center;
              text-decoration: underline;
              font-weight: bold;
              color: #a70d23;
              &:hover {
                text-decoration: none;
              }
            }
          }
          &.coverage a {
            display: block;
            width: 460px;
            height: 70px;
            margin: 20px auto 0 auto;
            &:hover {
              opacity: 0.5;
              transition: 0.7s;
            }
          }
        }
        hr {
          margin-bottom: 30px;
        }
        ul.magazine {
          margin: 20px 0 0 0;
          li {
            margin-right: 10px;
            display: inline-block;
            font-size: 12px;
            text-align: center;
            width: 130px;
            &.last {
              margin-right: 0;
            }
            img {
              margin-bottom: 10px;
            }
            &.start {
              width: 270px;
              img + img {
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
    ul.media_img {
      background: url(../images/wall.jpg);
      -webkit-background-size: 200px 200px;
      background-size: 200px 200px;
      padding: 20px;
      li {
        width: 220px;
        height: 450px;
        background: #fff;
        font-size: 14px;
        text-align: center;
        padding: 10px;
        margin: 5px;
        display: inline-block;
        vertical-align: middle;
        position: relative;
        a {
          width: 220px;
          height: 450px;
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          padding: 10px;
          &:after {
            content: "詳細を見る";
            width: 200px;
            height: 40px;
            font-size: 14px;
            color: #fff;
            line-height: 40px;
            display: block;
            background: #5c070c;
            position: absolute;
            bottom: 10px;
            left: 10px;
          }
          &:hover {
            transition: 0.7s;
            opacity: 0.7;
          }
        }
        img {
          width: 100%;
          height: auto;
          margin-bottom: 10px;
        }
      }
    }
    div.tel {
      background: #5c070c;
      padding: 18px 0;
      text-align: center;
      color: #fff;
      font-size: 18px;
      font-weight: bold;
      line-height: 36px;
      margin-top: 30px;
    }
  }
  &#footer.menu div.tel.sp {
    background: #5c070c;
    padding: 18px 0;
    text-align: center;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    line-height: 36px;
    margin-top: 30px;
  }
  &#wrapper {
    &.shourei div.tel {
      background: #5c070c;
      padding: 18px 0;
      text-align: center;
      color: #fff;
      font-size: 18px;
      font-weight: bold;
      line-height: 36px;
      margin-top: 30px;
    }
    div.tel a#tel, &.shourei div.tel a#tel {
      width: 457px;
      height: 36px;
      display: inline-block;
    }
    div.tel p, &.shourei div.tel p {
      font-size: 12px;
      font-weight: normal;
      margin: 10px 0 0 0;
      line-height: 22px;
    }
    div.tel p span {
      border: 1px solid #fff;
      padding: 3px 5px;
      margin: 0 0.5em  0 1em;
      display: inline;
    }
  }
  &#footer.menu div.tel.sp span {
    border: 1px solid #fff;
    padding: 3px 5px;
    margin: 0 0.5em  0 1em;
    display: inline;
  }
  &#wrapper {
    &.shourei {
      div.tel p span {
        border: 1px solid #fff;
        padding: 3px 5px;
        margin: 0 0.5em  0 1em;
        display: inline;
      }
      a.osusume {
	      width: 100%;
	      display: block;
	      margin-top: 30px;
      }
      a.chiebukuro {
        width: 100%;
        display: block;
        font-size: 22px;
        font-weight: bold;
        color: #fff;
        background: #ee7800;
        padding: 15px;
        margin-top: 30px;
        position: relative;
        span {
          line-height: 100px;
          display: inline-block;
          margin-left: 15px;
        }
        &:before {
          content: "詳しくはこちら";
          width: 150px;
          height: 25px;
          line-height: 25px;
          font-size: 14px;
          font-weight: normal;
          color: #ee7800;
          text-align: center;
          background: #fff;
          position: absolute;
          right: 15px;
          bottom: 15px;
        }
        &:after {
          content: "";
          width: 5px;
          height: 5px;
          display: block;
          border-top: 1px solid #ee7800;
          border-right: 1px solid #ee7800;
          transform: rotate(45deg);
          position: absolute;
          right: 25px;
          bottom: 25px;
        }
        &:hover {
          transition: 0.7s;
          opacity: 0.7;
        }
      }
    }
    a {
      &.interview {
        width: 730px;
        height: 225px;
        display: block;
        margin-top: 10px;
        margin-bottom: 20px;
        position: relative;
        transition: 0.7s;
        &:hover {
          opacity: 0.7;
          transition: 0.7s;
        }
      }
      &.interview_bt {
        &:hover {
          opacity: 0.7;
          transition: 0.7s;
        }
        width: 730px;
        line-height: 50px;
        display: block;
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        color: #fff;
        background: #5c070c;
        margin-bottom: 30px;
      }
    }
    div.voice_pickup ul.voicelist {
      margin: 25px 0 0 0;
      li {
        border-bottom: 2px dotted #9e9e9f;
        padding: 20px 0;
        font-size: 14px;
        position: relative;
        height: 200px;
        &.start {
          border-top: 2px dotted #9e9e9f;
        }
        &.last {
          height: 60px;
          padding: 0;
          margin-top: 30px;
          border-bottom: none;
          a {
            position: static;
            width: 730px;
            height: 60px;
            line-height: 60px;
            box-shadow: none;
            color: #FFF;
            text-align: center;
            font-size: 25px;
            font-weight: bold;
            display: block;
            border-radius: 10px;
            &:hover {
              color: #fff;
              width: 730px;
              height: 60px;
              border-radius: 10px;
            }
          }
        }
        img {
          position: absolute;
          top: 20px;
          left: 0;
        }
        h3 {
          margin-left: 320px;
        }
        p {
          margin-left: 338px;
        }
        h3 {
          background: url(../images/voicelist_arrow.gif) no-repeat left center;
          padding-left: 18px;
          line-height: 25px;
          margin-bottom: 10px;
        }
        p {
          line-height: 25px;
          text-align: left;
          font-weight: normal;
        }
        a {
          width: 410px;
          height: 30px;
          font-size: 14px;
          font-weight: bold;
          line-height: 30px;
          display: block;
          position: absolute;
          bottom: 20px;
          right: 2px;
          background: #76161b;
          color: #fff;
          text-align: center;
          border-radius: 5px;
          box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
          &:hover {
            background: rgba(92, 7, 12, 1);
            transition: 0.7s;
          }
        }
      }
    }
    dl {
      &.slidelist {
        margin: 15px 0 30px 0;
        dt br {
          display: none;
        }
      }
      &.readme_list dt br {
        display: none;
      }
    }
    div.price_section dl.price_order dt br {
      display: none;
    }
    dl {
      &.voice_list dt br, &.question_list dt br {
        display: none;
      }
      &.jiko_list {
        dl dt br, dt br {
          display: none;
        }
      }
      &.slidelist dt {
        font-size: 18px;
        font-weight: bold;
        line-height: 50px;
        padding-left: 15px;
        border-radius: 3px;
        background: #eeeceb;
        position: relative;
        margin-bottom: 10px;
        user-select: none;
        /*! CSS3 */
        -moz-user-select: none;
        /*! Firefox */
        -webkit-user-select: none;
        /*! Safari、Chromeなど */
        -ms-user-select: none;
        /*! IE10以降 */
      }
    }
    div.price_section dl.price_order dt {
      font-size: 18px;
      font-weight: bold;
      line-height: 50px;
      padding-left: 15px;
      border-radius: 3px;
      background: #eeeceb;
      position: relative;
      margin-bottom: 10px;
      user-select: none;
      /*! CSS3 */
      -moz-user-select: none;
      /*! Firefox */
      -webkit-user-select: none;
      /*! Safari、Chromeなど */
      -ms-user-select: none;
      /*! IE10以降 */
    }
    dl {
      &.profile_list dt, &.readme_list dt, &.question_list dt, &.flow_list dt, &.voice_list dt, &.privacy_list dt, &.jiko_list dt, &.nail_list dt {
        font-size: 18px;
        font-weight: bold;
        line-height: 50px;
        padding-left: 15px;
        border-radius: 3px;
        background: #eeeceb;
        position: relative;
        margin-bottom: 10px;
        user-select: none;
        /*! CSS3 */
        -moz-user-select: none;
        /*! Firefox */
        -webkit-user-select: none;
        /*! Safari、Chromeなど */
        -ms-user-select: none;
        /*! IE10以降 */
      }
      &.slidelist dt:after {
        content: url(../images/slidelist_arrow.png);
        position: absolute;
        top: 3px;
        right: 20px;
      }
    }
    div.price_section dl.price_order dt:after {
      content: url(../images/slidelist_arrow.png);
      position: absolute;
      top: 3px;
      right: 20px;
    }
    dl {
      &.profile_list dt:after, &.readme_list dt:after, &.question_list dt:after, &.voice_list dt:after, &.jiko_list dt:after, &.nail_list dt:after {
        content: url(../images/slidelist_arrow.png);
        position: absolute;
        top: 3px;
        right: 20px;
      }
      &.slidelist dd.entry {
        position: relative;
        margin: 20px 0;
        overflow: hidden;
      }
    }
    div.price_section dl.price_order dd.entry {
      position: relative;
      margin: 20px 0;
      overflow: hidden;
    }
    dl {
      &.profile_list dd.entry, &.readme_list dd.entry, &.question_list dd.entry, &.flow_list dd.entry, &.voice_list dd.entry, &.privacy_list dd, &.jiko_list dd, &.nail_list dd {
        position: relative;
        margin: 20px 0;
        overflow: hidden;
      }
      &.slidelist dd {
        div.slidelist_img {
          position: absolute;
          top: 0;
          right: 0;
          font-size: 0;
          &.style {
            top: 109px;
          }
        }
        &.entry p {
          line-height: 25px;
          margin: 0.5em 0;
        }
      }
    }
    div {
      &.price_section dl.price_order dd {
        &.entry p {
          line-height: 25px;
          margin: 0.5em 0;
        }
        strong {
          color: #76161b;
          font-weight: normal;
        }
      }
      &.access p strong {
        color: #76161b;
        font-weight: normal;
      }
    }
    dl.slidelist {
      dd {
        p {
          &.s {
            width: 355px;
          }
          &.m {
            width: 538px;
          }
          &.t {
            margin-top: 1em;
          }
        }
        div.slidelist_img img + img {
          margin-left: 10px;
        }
      }
      h2 {
        margin-bottom: 15px;
      }
      dd.entry {
        h3 {
          width: 355px;
          margin: 20px 0 10px 0;
          padding: 10px;
          display: block;
          background: #f4f2f1;
          box-sizing: border-box;
          font-weight: bold;
        }
        li {
          list-style: disc inside;
          font-size: 14px;
          padding-left: 0.5em;
          line-height: 25px;
          &.start {
            margin-top: 0.5em;
          }
          &.last {
            margin-bottom: 0.5em;
            &.style {
              list-style: none;
              font-size: 12px;
              margin-top: 0.5em;
            }
          }
        }
        &.s {
          min-height: 140px;
        }
        &.m {
          min-height: 190px;
        }
      }
    }
  }
  &#sidemenu {
    width: 250px;
    position: absolute;
    top: 0;
    left: 0;
    h2.shourei {
      border: 1px solid #666;
      border-bottom: none;
      border-radius: 8px 8px 0 0;
      overflow: hidden;
    }
    div.bana {
      margin-top: 20px;
      &:hover {
        opacity: 0.7;
        transition: 0.7s;
      }
    }
    ul {
      &.sidemenu_list {
        margin: 0 0 20px 0;
        padding-top: 5px;
        border: 1px solid #666;
        border-top: none;
        border-radius: 0 0 8px 8px;
        li {
          line-height: 20px;
          font-size: 14px;
          font-weight: bold;
          border-bottom: 1px dotted #ccc;
          &:last-child {
            border-bottom: none;
          }
          &.utsu {
            letter-spacing: -1px;
          }
          a {
            background: url(../images/sidemenu_arrow.gif) no-repeat 5px center;
            padding: 5px 0 5px 15px;
            display: block;
          }
          &.cat-item-6 {
            display: none;
          }
        }
      }
      &.banaarea li {
        margin-bottom: 10px;
        &.blogarea {
          border: 1px solid #666;
          border-radius: 8px;
          overflow: hidden;
          p {
            font-size: 14px;
            line-height: 30px;
            background: #5c070c;
            color: #fff;
            padding-left: 10px;
          }
          a {
            width: 100%;
            display: block;
          }
        }
        &.ekiten img {
	        display: block;
	        margin: 0 auto;
        }
        &.ekiten a {
	        border: none;
        }
        a {
          display: block;
          overflow: hidden;
          border: 1px solid #666;
          &:hover {
            opacity: 0.5;
            transition: 0.7s;
          }
        }
        ul.blog_list li {
          font-size: 14px;
          font-weight: bold;
          line-height: 20px;
          padding: 5px 0;
          border-bottom: 1px dotted #ccc;
          margin-bottom: 0;
          text-align: justify;
          &:last-child {
            border-bottom: none;
          }
          a {
            background: url(../images/sidemenu_arrow.gif) no-repeat 5px 4px;
            padding: 0 15px;
            border: none;
          }
        }
      }
    }
    
    a.lecture {
	 margin: 10px 0;
	 display: block;
	 
	   &:hover {
	     opacity: 0.5;
	     transition: 0.7s;
	   }
	 
    }

  }
  &.bana a {
    height: 40px;
    line-height: 20px;
    display: block;
    overflow: hidden;
  }
  &.ekiten_frame {
    font-size: 0;
    margin-top: 20px;
  }
  &.price.inner {
    width: 1200px;
    height: 270px;
    display: table;
  }
  &#container div.price.inner h1 {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    position: relative;
    top: calc(50% + 100px);
    left: 50%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    span {
      display: block;
      color: #fff;
      font-size: 16px;
      font-weight: normal;
      margin-top: 0.5em;
    }
  }
  &#wrapper {
    p.campaign {
      margin-bottom: 15px;
    }
    div {
      &.pricelist ul {
        background: #edebea;
        padding: 10px;
        li {
          display: inline-block;
          font-size: 14px;
          width: 230px;
          margin-right: 10px;
          margin-bottom: 0;
          padding: 10px;
          background: #fff;
          line-height: 20px;
          &.last {
            margin-right: 0;
          }
          h2 {
            font-size: 15px;
            line-height: 20px;
            color: #76161b;
            border-bottom: 1px solid #3e3a39;
            padding: 10px 0;
            margin: 0 0 10px 0;
            text-align: left;
          }
          img {
            width: 210px;
          }
          div {
            &.price_txt {
              min-height: 120px;
              padding: 0 0 10px 0;
              margin: 0 0 10px 0;
              border-bottom: 1px solid #3e3a39;
              p {
                font-size: 14px;
                background: none;
                text-align: left;
                padding-bottom: 0;
              }
            }
            &.price {
              margin-bottom: 10px;
              p {
                font-size: 20px;
                display: block;
                line-height: 100%;
                height: auto;
                border-bottom: none;
                background: none;
                margin: 5px 0;
                padding: 0;
                text-align: left;
                span {
                  background: #6da03c;
                  color: #fff;
                  padding: 0 5px;
                  border-radius: 2px;
                  margin-right: 0.5em;
                  font-size: 14px;
                  line-height: 30px;
                  display: inline-block;
                  vertical-align: middle;
                }
              }
            }
          }
        }
      }
      &.price_section h2 p span {
        background: #6da03c;
        color: #fff;
        padding: 0 5px;
        border-radius: 2px;
        margin-right: 0.5em;
        font-size: 14px;
        line-height: 30px;
        display: inline-block;
        vertical-align: middle;
      }
      &.pricelist ul li div.price p span + span, &.price_section h2 p span + span {
        background: none;
        color: #231815;
        font-size: 14px;
        margin: 0;
        padding: 0;
      }
      &.pricelist {
        ul li a {
          color: #fff;
          background: #9f9fa0;
          text-align: center;
          line-height: 30px;
          font-size: 14px;
          display: block;
          &:hover {
            background: #595757;
            transition: 0.7s;
          }
        }
        p {
          text-align: center;
          padding: 0 0 10px 0;
          font-size: 12px;
          background: #edebea;
        }
      }
      &.price_section {
        margin: 30px 0;
        position: relative;
        h2 {
          font-size: 0;
          img {
            display: block;
          }
          p {
            font-size: 20px;
            font-weight: normal;
            margin-top: 0.5em;
            display: inline-block;
            line-height: 100%;
            span + span {
              margin-right: 0.5em;
            }
          }
        }
        ul.symptom {
          width: 460px;
          margin: 30px 0;
          li {
            font-size: 14px;
            font-weight: bold;
            height: 40px;
            line-height: 40px;
            background: url(../images/section_icon.gif) no-repeat left center;
            padding-left: 30px;
            border-top: 2px dotted #9e9e9f;
            &.last {
              border-bottom: 2px dotted #9e9e9f;
            }
          }
        }
        div.imgposi02 {
          position: absolute;
          top: 102px;
          right: 0;
        }
        dl.price_order {
          margin-top: 30px;
          dd.entry ul {
            &.w_style {
              margin: 10px 0;
              width: auto;
              padding: 0;
              list-style: disc inside;
              height: 30px;
              li {
                height: 30px;
                background: none;
                line-height: 30px;
                float: left;
                border: none;
                padding-left: 1em;
                margin-right: 1em;
                font-size: 16px;
                font-weight: bold;
                color: #76161b;
              }
            }
            &.h_style {
              list-style: disc inside;
              margin: 15px 0;
              li {
                font-size: 16px;
                font-weight: bold;
                color: #76161b;
                padding-left: 1em;
                margin-bottom: 1em;
                p {
                  line-height: 25px;
                  font-size: 14px;
                  font-weight: normal;
                  color: #231815;
                  margin: 0.5em 0;
                }
              }
            }
          }
        }
      }
    }
  }
  &.profile.inner {
    width: 1200px;
    height: 270px;
    display: table;
  }
  &#container div.profile.inner h1 {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    position: relative;
    top: calc(50% + 100px);
    left: 50%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    
    span {
      display: block;
      color: #fff;
      font-size: 16px;
      font-weight: normal;
      margin-top: 0.5em;
    }
  }
  &#wrapper {
    div.profile {
      background: #eeefef;
      padding: 20px;
      h2 {
        position: relative;
        width: 390px;
        height: 235px;
        font-size: 18px;
        color: #fff;
        background: url(../images/profile_img01.jpg) no-repeat, #3e3a39;
        padding: 20px 20px 20px 215px;
        float: left;
        margin: 0 20px 20px 0;
        span {
          font-size: 12px;
          display: block;
          + span {
            font-size: 14px;
          }
        }
        ul {
          position: absolute;
          bottom: 20px;
          left: 215px;
        }
        li {
          width: 155px;
          font-size: 14px;
          list-style: disc inside;
        }
      }
      p {
        line-height: 25px;
        margin: 0.5em 0;
      }
    }
    dl.profile_list {
      margin: 20px 0;
      dd {
        &.history {
          margin: 20px 0;
        }
        &.entry p {
          margin: 0.5em 0;
        }
        &.history {
          p {
            width: 100% !important;
            line-height: 70px;
            font-size: 14px;
            margin: 0 0 10px 0;
            position: relative;
            &:after {
              content: "";
              width: 2px;
              height: 70px;
              display: block;
              background: #231815;
              position: absolute;
              top: 50%;
              left: 35px;
              z-index: -1;
            }
            &.last:after {
              content: none;
            }
            &.images {
              height: 240px;
              &.harabe {
                height: 260px;
              }
              &.rugby {
                height: 320px;
              }
              &:after {
                height: 240px;
                top: 35px;
              }
              &.harabe:after {
                height: 260px;
              }
              &.rugby:after {
                height: 320px;
              }
              img {
                position: absolute;
                top: 60px;
                left: 85px;
              }
            }
            &.line span {
              line-height: 30px;
            }
          }
          &.member p {
            font-size: 14px;
            line-height: 25px;
            margin: 0;
            padding: 0.5em;
            border-bottom: 2px dotted #9e9e9f;
            &:after {
              content: none;
            }
          }
        }
        &.entry p span {
          width: 70px;
          height: 70px;
          border-radius: 70px;
          border: 2px solid #231815;
          background: #fff;
          text-align: center;
          font-size: 13px;
          line-height: 15px;
          margin-right: 15px;
          display: inline-block;
          padding: 20px 0;
          vertical-align: top;
          &.caption {
            width: 100%;
            height: auto;
            line-height: 20px;
            border: none;
            border-radius: 0;
            padding: 0;
            text-align: left;
            display: block;
            background: none;
            position: absolute;
            bottom: 0;
            left: 85px;
          }
        }
      }
    }
    div {
      &.profile_photo {
        background: #eeeceb;
        padding: 30px 10px 30px 10px;
        margin: 0 0 30px 0;
        hr {
          width: 460px;
        }
        &.under {
          background: #eeeceb;
          padding: 30px 10px 30px 10px;
        }
        h2 {
          text-align: center;
          color: #5c070c;
          font-size: 24px;
          line-height: 22px;
          font-weight: bold;
          &#profile04 {
            padding-left: 125px;
            text-align: left;
            margin-top: -100px;
			padding-top: 100px;
          }
        }
        ul {
          margin: 20px 80px;
          text-align: center;
          li {
            width: 160px;
            display: inline-block;
            margin: 10px;
            text-align: center;
            font-size: 13px;
            vertical-align: middle;
          }
          &.person {
	          width: auto;
	          height: auto;
	          li {
		          height: auto;
		          display: inline-block;
		          margin: 10px;
		          text-align: center;
		          font-size: 13px;
		          line-height: 20px;
		          padding-left: 0;
		          vertical-align: middle;
		          border-top: none;
		          background: none;
		          img {
	                  width: 130px;
	                  border-radius: 130px;
	                  display: block;
	                  margin: 0 auto 10px auto;
		          }
	          }
          }
        }
      }
      &.media_bana p {
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        font-weight: bold;
        + p {
          margin-bottom: 10px;
        }
      }
      &.profile_photo {
        &.diploma {
          background: #eeeceb;
          padding: 30px 0 20px 0;
        }
        ul.award {
          width: 640px;
          margin: 30px auto 10px auto;
          li {
            width: 320px;
            display: inline-block;
            vertical-align: middle;
            margin: 0 0 20px 0;
            text-align: center;
            img {
              padding: 10px;
              margin-bottom: 5px;
              background: #fff;
            }
          }
        }
      }
      &.media_bana hr {
        margin-bottom: 30px;
      }
      &.profile_photo {
        ul.recommend {
          width: 690px;
          margin: 20px auto 0 auto;
          li {
            width: 345px;
            margin: 0;
            font-size: 14px;
            line-height: 24px;
            &.last {
              vertical-align: bottom;
              text-align: left;
              padding: 10px 0 0 15px;
              position: relative;
              img {
                position: absolute;
                top: -100px;
                right: 0;
              }
              h3 {
                margin-bottom: 15px;
              }
              ul {
                margin: 10px auto;
                li {
                  width: 100%;
                  text-align: left;
                  padding-left: 15px;
                  position: relative;
                  &.last {
                    padding: 0 0 0 15px;
                  }
                  &:before {
                    position: absolute;
                    top: 0;
                    left: 0;
                  }
                  &.start:before {
                    content: "①";
                  }
                  &.last:before {
                    content: "②";
                  }
                }
              }
            }
            p {
              margin-top: 10px;
            }
          }
        }
        hr {
          padding: 0 10px;
          margin: 0 auto 30px auto;
        }
      }
    }
    dl {
      &.profile_list dd.entry {
        p {
          &.l {
            width: 480px;
            margin: 0.5em 0;
            line-height: 25px;
          }
          &.r {
            margin: 0.5em 0 0.5em 320px;
            line-height: 25px;
          }
        }
        div {
          &.imgposi03, &.imgposi05 {
            position: absolute;
            top: 0;
            right: 0;
          }
          &.imgposi03 img {
            width: 230px;
            border-radius: 230px;
          }
          &.imgposi04 {
            position: absolute;
            top: 0;
            left: 0;
            img {
              width: 300px;
              &.bdr {
                border-radius: 300px;
              }
            }
          }
        }
      }
      &.readme_list dd.entry div.imgps06 img.bdr {
        border-radius: 300px;
      }
      &.profile_list dd.entry div.imgposi05 img {
        width: 230px;
      }
    }
  }
  &.readme.inner {
    width: 1200px;
    height: 270px;
    display: table;
  }
  &#container div.readme.inner h1 {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    position: relative;
    top: calc(50% + 100px);
    left: 50%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    span {
      display: block;
      color: #fff;
      font-size: 16px;
      font-weight: normal;
      margin-top: 0.5em;
    }
  }
  &#wrapper {
    div {
      &.readme {
        background: #ecebea;
        padding: 20px;
        h2 {
          text-align: center;
        }
        ul {
          width: 690px;
          margin: 20px 0 0 0;
          display: table;
          table-layout: fixed;
          li {
            font-size: 14px;
            display: table-cell;
            text-align: center;
            img {
              border-radius: 200px;
              display: block;
              margin: 0 auto 10px auto;
            }
          }
        }
      }
      &.summary {
        padding: 20px 0;
        background: #651c20;
        color: #fff;
        text-align: center;
      }
    }
    dl.readme_list {
      margin: 30px 0;
      h2 {
        margin-bottom: 15px;
        text-align: center;
      }
      dd.entry {
        p {
          margin: 0.5em 0;
          line-height: 18px;
          &.l {
            width: 410px;
          }
          &.r {
            margin-left: 330px;
          }
        }
        ul {
          &.r {
            margin-left: 330px;
          }
          list-style: disc inside;
          margin: 15px 0;
          li {
            font-size: 14px;
            font-weight: bold;
            line-height: 28px;
            color: #651c20;
            padding-left: 0.5em;
          }
        }
        div {
          &.imgps06 {
            position: absolute;
            top: 0;
            right: 0;
          }
          &.imgps07 {
            position: absolute;
            top: 0;
            left: 0;
            ul.graph {
              list-style: none;
              margin: 0;
              width: 300px;
              text-align: center;
              li {
                padding: 0;
                margin-bottom: 0.5em;
                &.last {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
        p {
          a {
            text-decoration: underline;
            color: blue;
            margin: 0 0.5em;
          }
          strong {
            text-decoration: underline;
            color: #72151b;
            font-weight: normal;
          }
        }
      }
    }
    div {
      &.reserve {
        margin: 30px 0;
        position: relative;
        h2 {
          margin-bottom: 1em;
          p {
            display: block;
            font-size: 14px;
            font-weight: normal;
            margin-top: 0.5em;
            + p {
              margin-top: 0;
            }
          }
        }
        dl.reserve_list {
          margin: 20px 0;
          dt {
            background: #edebea;
            font-size: 18px;
            font-weight: bold;
            color: #651c20;
            line-height: 50px;
            padding-left: 15px;
            border-radius: 3px;
            img {
              vertical-align: middle;
              margin-right: 0.5em;
            }
          }
          dd {
            padding: 0 15px;
            margin: 20px 0;
            position: relative;
            min-height: 115px;
            p {
              width: 470px;
            }
            img {
              position: absolute;
              top: -45px;
              right: 15px;
            }
            p {
              line-height: 25px;
            }
          }
        }
      }
      &.readme div.how {
        position: relative;
        margin: 20px 0 0 0;
        p {
          margin: 0.5em 0 0.5em 320px;
        }
        img {
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 300px;
        }
      }
    }
  }
  &.access.inner {
    width: 1200px;
    height: 270px;
    display: table;
  }
  &#container div.access.inner h1 {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    position: relative;
    top: calc(50% + 100px);
    left: 50%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    span {
      display: block;
      color: #fff;
      font-size: 16px;
      font-weight: normal;
      margin-top: 0.5em;
    }
  }
  &#wrapper div {
    &.access {
      margin-bottom: 30px;
      h2 {
        font-size: 18px;
        font-weight: bold;
        line-height: 50px;
        padding-left: 15px;
        border-radius: 3px;
        background: #eeeceb;
        position: relative;
        margin-bottom: 20px;
      }
      div#map {
        width: 100%;
        height: 400px;
        margin-top: 20px;
      }
      h3 {
	      height: 40px;
	      font-size: 16px;
	      line-height: 40px;
	      text-align: center;
	      background: #eeeceb;
	      margin-bottom: 20px;
	      border-radius: 0 0 5px 5px;
      }
      iframe {
	      vertical-align: bottom;
	      margin-top: 30px;
      }
      a.bt {
        width: 300px;
        line-height: 40px;
        display: block;
        text-align: center;
        margin: 0 auto;
        background: #5c070c;
        color: #fff;
        font-weight: bold;
        margin-bottom: 20px;
        &:hover {
          transition: 0.7s;
          opacity: 0.7;
        }
      }
      p {
        text-align: center;
        margin: 0.5em 0;
      }
    }
    &.anime_map {
      margin-top: 30px;
      img {
        margin: 0 auto;
        display: block;
        border: 3px solid #5c070c;
      }
    }
  }
  &.flow.inner {
    width: 1200px;
    height: 270px;
    display: table;
  }
  &#container div.flow.inner h1 {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    position: relative;
    top: calc(50% + 100px);
    left: 50%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    span {
      display: block;
      color: #fff;
      font-size: 16px;
      font-weight: normal;
      margin-top: 0.5em;
    }
  }
  &#wrapper dl {
    &.flow_list {
      margin: 0 0 30px 0;
      position: relative;
      h2 {
        color: #5c070c;
        font-size: 24px;
        line-height: 100%;
        font-weight: bold;
        margin-bottom: 10px;
      }
    }
    &.question_list h2 {
      color: #5c070c;
      font-size: 24px;
      line-height: 100%;
      font-weight: bold;
      margin-bottom: 10px;
    }
    &.flow_list {
      dt {
        color: #651c20;
        img {
          vertical-align: middle;
          margin-right: 0.5em;
        }
      }
      dd.entry {
        padding: 0 15px;
        position: relative;
        overflow: visible;
        min-height: 115px;
        img {
          position: absolute;
          top: -45px;
          right: 15px;
        }
        p {
          line-height: 25px;
          width: 470px;
          a {
            text-decoration: underline;
            color: blue;
          }
        }
      }
      div.attention {
        background: #5c070c;
        padding: 18px 0;
        text-align: center;
        color: #fff;
        font-size: 15px;
        font-weight: bold;
      }
    }
    &.question_list dd.entry ul {
      margin: 10px 0;
      list-style: disc inside;
      li {
        font-size: 14px;
        padding: 0 15px 0 4em;
        line-height: 25px;
      }
    }
  }
  &.voice.inner {
    width: 1200px;
    height: 270px;
    display: table;
  }
  &#container div.voice.inner h1 {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    position: relative;
    top: calc(50% + 100px);
    left: 50%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    span {
      display: block;
      color: #fff;
      font-size: 16px;
      font-weight: normal;
      margin-top: 0.5em;
    }
  }
  &#wrapper {
    dl.voice_list {
      margin-bottom: 30px;
      dd.entry {
        padding: 0 15px;
        height: auto !important;
        height: 100%;
        min-height: 100%;
        div.voice_wrapper {
          width: 450px;
          overflow: hidden;
        }
        p {
          line-height: 25px;
          &.l, &.r {
            width: 450px;
          }
        }
        hr {
          &.l.p, &.r.p {
            width: auto;
          }
        }
        p.bdr01 {
          float: left;
          margin: 0 20px 20px 0;
          img {
            border-radius: 200px;
          }
        }
        img {
          &.bdr01 {
            border-radius: 200px;
            float: left;
            margin: 0 20px 0 0;
          }
          &.bdr02 {
            border-radius: 200px;
            float: right;
            margin: 0 0 0 20px;
          }
        }
        p.r {
          margin-left: 250px;
        }
        hr {
          &.l {
            width: 450px;
            margin: 20px 0;
          }
          &.r {
            width: 450px;
            margin: 20px 0 20px 250px;
          }
        }
        span {
          display: block;
          text-align: right;
        }
        div {
          &.voice_r {
            position: absolute;
            top: 0;
            right: 15px;
          }
          &.voice_l {
            position: absolute;
            top: 0;
            left: 15px;
          }
          &.voice_r img, &.voice_l img {
            width: 230px;
            height: auto;
            display: block;
            border: 1px solid #ccc;
            border-bottom: none;
          }
          &.voice_r a, &.voice_l a {
            display: block;
            text-align: center;
            color: #fff;
            background: #76161b;
            font-size: 15px;
            font-weight: bold;
            line-height: 40px;
          }
          &.voice_r a:hover, &.voice_l a:hover {
            transition: 0.7s;
            opacity: 0.7;
          }
        }
      }
    }
    &.shourei.onpa {
      ul {
        margin: 30px auto;
        li {
          width: 350px;
          height: 350px;
          display: inline-block;
          border: 1px solid #ccc;
          padding: 25px;
          background: none;
          &.start {
            border-top: 1px solid #ccc;
          }
          &.last {
            width: 350px;
            height: 350px;
            padding-left: 25px;
            margin-left: 30px;
            border-bottom: 1px solid #ccc;
            position: static;
          }
        }
      }
      h2 {
        font-size: 35px;
        font-family: "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
        line-height: 45px;
        text-align: center;
        color: #5c070c;
        background: none;
        margin: 0 0 30px 0;
        padding: 0;
      }
      div.onpa_img {
        text-align: center;
        margin-bottom: 30px;
      }
      h3 {
        font-size: 20px;
        line-height: 50px;
        margin: 0 0 20px 0;
        padding-left: 20px;
        color: #fff;
        border: none;
        background: #5c070c;
        &.recommend {
          font-size: 18px;
          line-height: 30px;
          font-weight: bold;
          color: #5c070c;
          background: none;
          margin: 30px 0 20px 0;
          padding: 0;
          position: relative;
          strong {
            border-bottom: 2px solid #5c070c;
            padding-bottom: 10px;
          }
        }
      }
      ul.recommend_list {
        margin: 20px 0 50px 0;
        li {
          width: 100%;
          height: 85px;
          display: list-item;
          font-size: 20px;
          line-height: 85px;
          color: #5c070c;
          border: none;
          padding: 0;
          margin-bottom: 10px;
          &.last {
            margin-left: 0;
          }
          &.num01 {
            background: url(../images/jiritsu_num01.png) no-repeat left center;
            -webkit-background-size: 85px 85px;
            background-size: 85px 85px;
            padding-left: 95px;
          }
          &.num02 {
            background: url(../images/jiritsu_num02.png) no-repeat left center;
            -webkit-background-size: 85px 85px;
            background-size: 85px 85px;
            padding-left: 95px;
          }
          &.num03 {
            background: url(../images/jiritsu_num03.png) no-repeat left center;
            -webkit-background-size: 85px 85px;
            background-size: 85px 85px;
            padding-left: 95px;
          }
          &.num04 {
            background: url(../images/jiritsu_num04.png) no-repeat left center;
            -webkit-background-size: 85px 85px;
            background-size: 85px 85px;
            padding-left: 95px;
          }
        }
      }
      div {
        &.copy {
          font-size: 19px;
          font-family: "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
          margin-bottom: 10px;
        }
        &.about {
          position: relative;
          p {
            width: 523px;
            line-height: 24px;
            margin: 0;
          }
          div.about_img {
            position: absolute;
            top: 0;
            right: 0;
          }
        }
        &.effect {
          position: relative;
        }
      }
    }
  }
}

/*!nav*/

/*!header h1*/

/*!main*/

/*!slide*/

@media screen and (min-width: 768px) {
  div#wrapper {
    dl.slidelist dt:hover, div.price_section dl.price_order dt:hover {
      background: #888;
      color: #fff;
      cursor: pointer;
    }
    dl {
      &.profile_list dt:hover, &.readme_list dt:hover, &.question_list dt:hover, &.voice_list dt:hover, &.jiko_list dt:hover, &.nail_list dt:hover {
        background: #888;
        color: #fff;
        cursor: pointer;
      }
      &.slidelist dt:hover:after {
        content: url(../images/slidelist_arrow_hover.png);
        position: absolute;
        top: 3px;
        right: 20px;
      }
    }
    div.price_section dl.price_order dt:hover:after {
      content: url(../images/slidelist_arrow_hover.png);
      position: absolute;
      top: 3px;
      right: 20px;
    }
    dl {
      &.profile_list dt:hover:after, &.readme_list dt:hover:after, &.question_list dt:hover:after, &.voice_list dt:hover:after, &.jiko_list dt:hover:after, &.nail_list dt:hover:after {
        content: url(../images/slidelist_arrow_hover.png);
        position: absolute;
        top: 3px;
        right: 20px;
      }
    }
  }
}

/*!sidemenu*/

/*!price*/

/*!profile*/

/*!readme*/

/*! !!コメント */

/*!access*/

/*!flow*/

/*!voice*/

/*!超音波（ハイボルテージ）*/

img.size-large.wp-image-1810.alignleft , img.wp-image-2145 {
  float: none !important;
}
img.wp-image-2145 {
	width: 100%;
	height: auto;
	margin: 0 !important;
}

div {
  &#wrapper.shourei {
    &.onpa {
      div.effect div.image {
        width: 300px;
        height: 225px;
        position: absolute;
        bottom: -320px;
        right: 0;
      }
      ul.treat {
        margin: 30px auto 0 auto;
        li {
          width: 350px;
          height: 300px;
          padding: 0;
          border: none;
        }
      }
    }
    dl.voice_list {
      dt {
        list-style: none;
        color: #231815;
        &:hover {
          color: #fff;
        }
      }
      dd {
        font-weight: normal;
        padding: 0 15px;
        height: auto !important;
        height: 100%;
        min-height: 100%;
        position: relative;
        margin: 20px 0;
        overflow: hidden;
        &:before {
          content: none;
        }
        div {
          &.voice_r {
            position: absolute;
            top: 0;
            right: 15px;
            a {
              display: block;
              text-align: center;
              color: #fff;
              background: #76161b;
              font-size: 15px;
              font-weight: bold;
              line-height: 40px;
              &:hover {
                transition: 0.7s;
                opacity: 0.7;
              }
            }
            img {
              width: 230px;
              height: auto;
              display: block;
              border: 1px solid #ccc;
              border-bottom: none;
            }
          }
          &.voice_wrapper {
            width: 450px;
            overflow: hidden;
          }
        }
        p {
          &.bdr01 {
            float: left;
            margin: 0 20px 0 0;
            img {
              border-radius: 200px;
              float: none;
              margin: 0;
            }
          }
          &.l {
            width: 450px;
            line-height: 25px;
            margin: 0;
          }
        }
        hr {
          width: auto;
          margin: 20px 0;
        }
        p.l span {
          text-align: right;
        }
      }
    }
  }
  &.question.inner {
    width: 1200px;
    height: 200px;
    display: table;
  }
  &#container div.question.inner h1 {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    position: relative;
    top: calc(50% + 100px);
    left: 50%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    span {
      display: block;
      color: #fff;
      font-size: 16px;
      font-weight: normal;
      margin-top: 0.5em;
    }
  }
  &#wrapper dl.question_list {
    margin-bottom: 30px;
    dt {
      &:before {
        content: url(../images/q.png) no-repeat;
        position: absolute;
        top: 10px;
        left: 15px;
      }
      padding-left: 50px;
    }
    dd {
      background: url(../images/a.png) no-repeat 15px top;
      &.entry p {
        padding: 0 15px 0 55px;
        line-height: 25px;
        &.sick {
          font-size: 16px;
          font-weight: bold;
          padding-top: 5px;
          padding-bottom: 15px;
        }
        &.attention {
          border: 1px solid #231815;
          margin: 0 10px 20px 55px;
          padding: 10px 10px 10px 25px;
          font-size: 13px;
          line-height: 20px;
          position: relative;
          clear: both;
          &:before {
            content: "※";
            position: absolute;
            top: 10px;
            left: 10px;
          }
        }
      }
    }
    div.sick {
      margin-left: 45px;
      font-size: 0;
    }
    dd.entry {
      dl {
        padding: 0 10px;
        dt {
          text-align: left;
          line-height: 35px;
          font-size: 16px;
          border: 1px solid #CCC;
          color: #444;
          border-radius: 20px;
          padding: 0 20px;
          margin-bottom: 10px;
          background: none;
          cursor: auto;
          &:before, &:after {
            content: none;
          }
        }
        &.short {
          width: 50%;
          display: inline-block;
          vertical-align: top;
        }
        dd {
          line-height: 25px;
          font-size: 14px;
          padding: 0 20px 20px 20px;
          background: none;
        }
      }
      p a {
        color: blue;
        text-decoration: underline;
      }
    }
  }
  &.privacy.inner {
    width: 1200px;
    height: 270px;
    display: table;
  }
  &#container div.privacy.inner h1 {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    position: relative;
    top: calc(50% + 100px);
    left: 50%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    span {
      display: block;
      color: #fff;
      font-size: 16px;
      font-weight: normal;
      margin-top: 0.5em;
    }
  }
  &#wrapper dl.privacy_list dd {
    p {
      padding: 0 15px;
      line-height: 25px;
    }
    ul {
      padding: 0 15px;
      margin: 10px 0;
      list-style: disc inside;
      li {
        font-size: 14px;
        line-height: 25px;
      }
    }
  }
  &.sitemap.inner {
    width: 1200px;
    height: 270px;
    display: table;
  }
  &#container div.sitemap.inner h1 {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    position: relative;
    top: calc(50% + 100px);
    left: 50%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    span {
      display: block;
      color: #fff;
      font-size: 16px;
      font-weight: normal;
      margin-top: 0.5em;
    }
  }
  &#wrapper div {
    &.sitemap {
      position: relative;
      font-size: 0;
    }
    &.sitemap_list {
      width: 355px;
      display: inline-block;
      vertical-align: top;
      + div.sitemap_list {
        margin-left: 20px;
      }
      div.menu {
        margin-bottom: 30px;
        h2 {
          font-size: 17px;
          line-height: 100%;
          color: #76161b;
          padding-bottom: 10px;
          border-bottom: 3px solid #76161b;
          margin-bottom: 15px;
        }
        ul {
          list-style: disc inside;
          padding: 0 0 0 5px;
          li {
            font-size: 13px;
            line-height: 30px;
            margin-bottom: 5px;
            a {
              display: inline-block;
              border-bottom: 0px solid #76161b;
              line-height: 30px;
              height: 30px;
              &:hover {
                color: #76161b;
                border-bottom-width: 3px;
              }
            }
          }
        }
      }
    }
  }
  &.jiko.inner {
    width: 1200px;
    height: 270px;
    display: table;
  }
  &#container div.jiko.inner h1 {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    position: relative;
    top: calc(50% + 100px);
    left: 50%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    span {
      display: block;
      color: #fff;
      font-size: 16px;
      font-weight: normal;
      margin-top: 1em;
    }
  }
  &#wrapper {
    div.jiko_list {
      margin: 0 0 30px 0;
      position: relative;
      h2, h3 {
        text-align: center;
      }
      h2 {
        margin-bottom: 30px;
        span {
          display: block;
          margin-top: 0.5em;
        }
      }
      ul {
        margin: 30px 0;
        width: 550px;
        li {
          font-size: 14px;
          font-weight: bold;
          height: 40px;
          line-height: 40px;
          background: url(../images/section_icon.gif) no-repeat left center;
          padding-left: 30px;
          border-top: 2px dotted #9e9e9f;
          &.start {
            border-top: none;
            padding: 0;
            background: none;
            margin-bottom: 10px;
            height: auto;
            h2 {
              text-align: left;
              color: #5c070c;
              font-size: 24px;
              line-height: 100%;
              font-weight: bold;
              margin-bottom: 0;
            }
          }
          &.last {
            border-bottom: 2px dotted #9e9e9f;
          }
        }
      }
      div.imgps08 {
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
    dl.jiko_list {
      margin: 0 0 30px 0;
      position: relative;
      h2 {
        margin-bottom: 20px;
        text-align: center;
        img {
          margin-bottom: 10px;
        }
        p {
          text-align: center;
          font-size: 14px;
          font-weight: normal;
          line-height: 25px;
        }
      }
      dd.entry {
        p {
          line-height: 25px;
          &.l {
            line-height: 25px;
            margin: 0.5em 0 0.5em 220px;
          }
        }
        &.open {
          min-height: inherit !important;
        }
        div.jikolist_img {
          position: absolute;
          top: 0;
          left: 0;
        }
      }
    }
  }
  &.nail.inner {
    width: 1200px;
    height: 270px;
  }
  &#container div.nail.inner h1 {
    text-align: center;
    bottom: 0;
    background: none;
    span {
      display: block;
      color: #fff;
      font-size: 16px;
      font-weight: normal;
      margin-top: 0.5em;
    }
  }
  &#wrapper {
    div {
      &.nail_list {
        margin: 0 0 30px 0;
        position: relative;
        ul {
          margin: 30px 0;
          width: 460px;
          li {
            font-size: 14px;
            font-weight: bold;
            height: 40px;
            line-height: 40px;
            background: url(../images/section_icon.gif) no-repeat left center;
            padding-left: 30px;
            border-top: 2px dotted #9e9e9f;
            &.last {
              border-bottom: 2px dotted #9e9e9f;
            }
          }
        }
        div.imgps09 {
          position: absolute;
          top: 80px;
          right: 0;
        }
      }
      &.nail_example {
        background: #eeeceb;
        padding: 30px;
        margin: 30px 0;
        h2 {
          text-align: center;
        }
        ul.nail_example {
          width: 670px;
          display: table;
          table-layout: fixed;
          margin-top: 20px;
          position: relative;
          li {
            display: table-cell;
            text-align: center;
            font-size: 14px;
            &.start:after {
              content: url(../images/nail_arrow.png) no-repeat center center;
              position: absolute;
              bottom: 23px;
              left: 180px;
            }
            &.last:before {
              content: url(../images/nail_arrow.png) no-repeat center center;
              position: absolute;
              bottom: 23px;
              right: 150px;
            }
            img {
              display: block;
              border-radius: 110px;
              margin: 0 auto 10px auto;
            }
          }
        }
      }
    }
    dl.nail_list {
      margin: 0 0 30px 0;
      position: relative;
      h2 {
        margin-bottom: 15px;
        text-align: center;
      }
      dt {
        list-style: decimal inside;
        display: list-item;
      }
      dd {
        &.entry p {
          line-height: 25px;
          margin: 0.5em 0;
        }
        ul li {
          width: 50%;
          display: inline-block;
          position: relative;
          &.start:after {
            content: "";
            width: 50px;
            height: 45px;
            display: block;
            background: url(../images/img_arrow.gif) no-repeat;
            background-size: 50px 45px;
            position: absolute;
            top: 0;
            bottom: 0;
            right: -25px;
            margin: auto;
          }
        }
        &.entry {
          p.r {
            margin-left: 220px;
          }
          img {
            &.imgps10 {
              position: absolute;
              top: 0 !important;
              left: 0;
              right: auto !important;
            }
            &.imgps11 {
              top: 0 !important;
            }
            display: block;
            margin: 0 auto;
          }
        }
      }
    }
    div {
      &.nail_example {
        h2 p {
          font-weight: normal;
          font-size: 14px;
          margin-top: 10px;
          line-height: 25px;
        }
        hr {
          margin: 20px 0;
        }
        h3 {
          font-size: 18px;
        }
        ul {
          margin: 10px 0;
          list-style: decimal inside;
          li {
            font-size: 14px;
            text-indent: -1em;
            padding-left: 1em;
            margin: 1em 0;
            line-height: 25px;
          }
        }
        div {
          &.oniduka, &.phenol {
            position: relative;
            margin: 35px 0 20px 0;
            color: #fff;
          }
          &.oniduka {
            min-height: 294px;
            h2 {
              font-size: 18px;
              line-height: 40px;
            }
          }
          &.phenol h2 {
            font-size: 18px;
            line-height: 40px;
          }
          &.good {
            position: absolute;
            top: 0;
            left: 0;
            width: 325px;
            min-height: 294px;
            background: rgba(255, 255, 255, 0.8);
            border: 2px solid #5c070c;
            h2 {
              background: #5c070c;
              &:before {
                content: url(../images/good.png);
                position: absolute;
                top: -25px;
                left: -25px;
              }
              &:after {
                content: url(../images/good_arrow.gif);
                position: absolute;
              }
            }
          }
          &.oniduka {
            div {
              &.good h2:after, &.bad h2:after {
                bottom: 227px;
                left: 162px;
              }
            }
            ul {
              list-style: disc inside;
              padding: 5px 15px 0 15px;
              font-size: 14px;
            }
          }
          &.phenol ul {
            list-style: disc inside;
            padding: 5px 15px 0 15px;
            font-size: 14px;
          }
          &.oniduka ul li, &.phenol ul li {
            color: #5c070c;
            margin: 0;
            line-height: 25px;
            font-size: 13px;
          }
          &.bad {
            position: absolute;
            top: 0;
            right: 0;
            width: 325px;
            min-height: 294px;
            background: rgba(255, 255, 255, 0.8);
            border: 2px solid #666666;
            h2 {
              background: #666666;
              &:before {
                content: url(../images/bad.png);
                position: absolute;
                top: -25px;
                left: -25px;
              }
              &:after {
                content: url(../images/bad_arrow.gif);
                position: absolute;
              }
            }
            ul li {
              color: #666666;
              margin: 0;
              line-height: 25px;
              font-size: 13px;
            }
          }
          &.phenol {
            min-height: 219px;
            margin-bottom: 0;
            div {
              &.good, &.bad {
                min-height: 219px;
                margin-bottom: 0;
              }
              &.good h2:after, &.bad h2:after {
                bottom: 152px;
                left: 162px;
              }
            }
          }
        }
      }
      &.nail_really {
        height: 560px;
        position: relative;
        background: url(../images/nail_img07.png) no-repeat center 90px;
        p {
          width: 500px;
          margin: 0.5em 0;
          line-height: 25px;
        }
        h2 {
          margin-bottom: 15px;
        }
      }
      &.nail_price {
        margin: 0 0 30px 0;
        position: relative;
        padding: 20px;
        border: 5px solid #c62837;
        border-radius: 10px;
        h2 {
          color: #c62837;
          line-height: 100%;
          display: inline-block;
          margin-right: 10px;
        }
        ul {
          display: inline-block;
          li {
            width: 285px;
            display: inline-block;
          }
        }
        img {
          vertical-align: middle;
        }
      }
    }
    dl.nail_list {
      &.s dt {
        list-style: none;
      }
      p span {
        background: #666;
        padding: 0 5px;
        color: #fff;
        line-height: 25px;
        font-size: 13px;
        margin: 0.5em 0;
        display: inline-block;
      }
      &.s dd.entry img {
        position: absolute;
        top: 80px;
        right: 0;
        width: 200px;
      }
    }
  }
  &.blog.inner {
    width: 1200px;
    height: 270px;
    display: table;
  }
  &#container div.blog.inner h1 {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    position: relative;
    top: calc(50% + 100px);
    left: 50%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    span {
      display: block;
      color: #fff;
      font-size: 16px;
      font-weight: normal;
      margin-top: 0.5em;
    }
  }
  &.contact.inner {
    width: 1200px;
    height: 270px;
    display: table;
  }
  &#container div.contact.inner h1 {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    position: relative;
    top: calc(50% + 100px);
    left: 50%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    span {
      display: block;
      color: #fff;
      font-size: 16px;
      font-weight: normal;
      margin-top: 1em;
    }
  }
  &#wrapper {
	
	&.contact {
		h3 {
			line-height: 40px;
			margin: 0 20px;			
		}
		ul.contact_type {
			margin: 0 20px 20px 20px;

			li {
				width: 335px;
				height: 80px;
				line-height: 78px;
				font-size: 14px;
				border: 1px solid #989898;
				display: inline-block;
				position: relative;
				
				&.start {
					margin-right: 20px;
				}
				
				input {
					position: absolute;
					top: 0;
					bottom: 0;
					left: 30px;
					margin: auto;
				}
				
				label {
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
					padding-left: 50px;
					cursor: pointer;
				}
				
			}
		}  
	}
	
    dl {
      &.contact_list {
        margin: 0 0 20px 0;
        position: relative;
        h2 p {
          margin-top: 0.5em;
          margin-bottom: 3em;
          font-size: 14px;
          font-weight: normal;
          line-height: 25px;
        }
      }
      &.privacy_list h2 p {
        margin-top: 0.5em;
        margin-bottom: 3em;
        font-size: 14px;
        font-weight: normal;
        line-height: 25px;
      }
      &.contact_list {
        dt, dd {
          margin: 0 20px;
          font-size: 14px;
        }
        dt {
          line-height: 30px;
          span {
            color: red;
          }
        }
        dd {
          margin-bottom: 1em;
          input {
            line-height: 20px;
            font-size: 14px;
            border: 1px solid #989898;
            padding: 0.5em;
            &#mfp_element_1, &#mfp_element_2, &#mfp_element_8, &#mfp_element_9, &#mfp_element_10 {
              width: 100% !important;
            }
            &.postfield {
              width: 50% !important;
            }
            &#mfp_element_6, &#mfp_element_7 {
              width: 70% !important;
            }
          }
          textarea.mfp_element_textarea {
            width: 100% !important;
            height: 200px !important;
            resize: none;
            font-size: 14px;
            padding: 0.5em;
            border: 1px solid #989898;
          }
          label#mfp_element_12_label {
            display: block;
            text-align: center;
          }
          ol li {
            font-size: 14px;
            margin-left: 1em;
            margin-bottom: 0.5em;
            span {
              margin-right: 0.5em;
            }
            select {
              line-height: 20px;
              -webkit-appearance: none;
              border-radius: 0;
              width: 200px;
              border: 1px solid #989898;
              padding: 0.5em;
              font-size: 14px;
              position: relative;
              background: url(../images/arrow02.png) no-repeat right 5px center;
              cursor: pointer;
              &:hover {
                background: url(../images/arrow.png) no-repeat right 5px center;
              }
            }
          }
        }
      }
    }
    div {
      &.bt {
        text-align: center;
        margin-top: 20px;
        margin-bottom: 30px;
        padding-top: 20px;
        border-top: 2px dotted #9e9e9f;
        font-size: 0;
        button {
          width: 245px;
          padding: 0;
          margin: 0 10px 0 0;
          line-height: 40px;
          background: #595757;
          color: #fff;
          border: none;
          font-size: 14px;
          font-weight: bold;
          display: inline-block;
          cursor: pointer;
          &.submit {
            background: #76161b;
            margin: 0;
          }
          &:hover {
            background: #231815;
            transition: 0.7s;
          }
          &.submit:hover {
            background: rgba(92, 7, 12, 1);
          }
        }
      }
      &.contact_list {
        margin: 0 0 30px 0;
        min-height: 700px;
        h2 {
          margin-bottom: 30px;
        }
        p {
          margin: 0.5em 0;
          font-size: 14px;
          font-weight: normal;
        }
      }
    }
    a.contact_bt {
      display: block;
      width: 300px;
      line-height: 40px;
      text-align: center;
      margin: 50px 0 50px 10px;
      background: #76161b;
      color: #fff;
      font-weight: bold;
      transition: 0.7s;
      &:hover {
        background: rgba(92, 7, 12, 1);
      }
    }
    &.blog {
      position: relative;
    }
  }
  &.shourei.inner {
    width: 1200px;
    height: 270px;
    display: table;
  }
  &#container div.shourei.inner h1 {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    position: relative;
    top: calc(50% + 100px);
    left: 50%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    span {
      display: block;
      color: #fff;
      font-size: 16px;
      font-weight: normal;
      margin-top: 0.5em;
    }
  }
  &#wrapper {
    div.shourei_list {
      margin: 0 0 30px 0;
      position: relative;
      min-height: 630px;
      h1 {
        font-size: 20px;
        line-height: 100%;
        font-weight: bold;
        margin-bottom: 10px;
      }
      div.date {
        font-size: 0;
      }
    }
    dl.postlist.latest-articles dt div.date {
      font-size: 0;
    }
    div.shourei_list div.date p {
      font-size: 14px;
      line-height: 100%;
    }
    &.blog {
      div.tel {
        width: 730px;
        margin-bottom: 30px;
      }
      ul.kiji {
        width: 730px;
        height: 150px;
        margin: 30px 0 50px 0;
        li {
          width: 355px;
          height: 150px;
          font-size: 15px;
          line-height: 20px;
          position: relative;
          &:hover {
            cursor: pointer;
          }
          + li {
            margin-left: 20px;
          }
          &.next {
            padding-left: 20px;
            float: left;
          }
          &.prev {
            padding-right: 20px;
            float: right;
          }
          &.next a:before {
            content: "";
            width: 40px;
            height: 40px;
            background: url(../images/next_arrow.gif) no-repeat;
            position: absolute;
            top: 55px;
            left: 0;
            z-index: 5;
          }
          &:hover.next a:before {
            content: "";
            background: url(../images/next_arrow_on.gif) no-repeat;
            transition: 0.7s;
          }
          &.prev a:before {
            content: "";
            width: 40px;
            height: 40px;
            background: url(../images/prev_arrow.gif) no-repeat;
            position: absolute;
            top: 55px;
            right: 0;
            z-index: 5;
          }
          &:hover.prev a:before {
            content: "";
            background: url(../images/prev_arrow_on.gif) no-repeat;
            transition: 0.7s;
          }
          &.next img {
            position: absolute;
            top: 0;
            left: 20px;
          }
          &.prev img {
            position: absolute;
            top: 0;
            right: 20px;
          }
          a {
            display: block;
            width: 355px;
            height: 150px;
            position: absolute;
            top: 0;
            left: 0;
            &:hover {
              transition: 0.7s;
              opacity: 0.7;
            }
          }
          p {
            width: 165px;
            font-weight: bold;
          }
          &.next p {
            margin: 10px 0 0 190px;
          }
          &.prev p {
            margin: 10px 0 0 0;
          }
          &.next span {
            margin: 5px 0 0 190px;
            display: block;
            font-size: 14px;
          }
          &.prev span {
            margin: 5px 0 0 0;
            display: block;
            font-size: 14px;
          }
          div.bt {
            width: 165px;
            height: 25px;
            line-height: 21px;
            text-align: center;
            color: #5c070c;
            border: 2px solid #5c070c;
            font-size: 15px;
            margin: 0;
            padding: 0;
            position: absolute;
            bottom: 10px;
          }
          &.next div.bt {
            margin-left: 190px;
          }
          a:hover div.bt {
            transition: 0.7s;
            color: #FFF;
            background: #5c070c;
          }
        }
      }
    }
    dl.postlist.latest-articles dt div.date p {
      display: inline-block;
      font-size: 13px;
      line-height: 20px;
      &.day {
        margin-right: 1em;
      }
    }
    div.shourei_list div.date p.cate_name a, dl.postlist.latest-articles dt div.date p.cate_name a {
      line-height: 20px;
      display: inline-block;
      margin: 0 0.5em;
      font-weight: bold;
    }
    div.shourei_list div.date p.cate_name a:hover, dl.postlist.latest-articles dt div.date p.cate_name a:hover {
      color: #9f4448;
      transition: 0.7s;
      font-weight: bold;
    }
    div.shourei_list div.article {
      margin: 30px 0;
      min-height: 870px;
    }
  }
}

/*!固定ページvoice*/

/*!question*/

/*!privacy*/

/*!sitemap*/

/*!jiko*/

/*!nail*/

/*!blog*/

/*!contact*/

/*!WP*/

img.attachment-730_500.wp-post-image {
  margin-bottom: 30px;
}

div#wrapper {
  div.shourei_list div.article {
    img.custom_img {
      margin-bottom: 30px;
    }
    h2 {
      line-height: 50px;
      font-size: 20px;
      font-weight: normal;
      background: #5c070c;
      margin: 0 0 20px 0;
      padding-left: 15px;
      color: #FFF;
    }
    h3 {
      font-size: 18px;
      font-weight: normal;
      border: 1px solid #5c070c;
      margin: 1em 0 0.5em 0;
      display: inline-block;
      padding: 0 15px;
      color: #5c070c;
    }
    p {
      font-size: 18px;
      line-height: 30px;
      padding: 0 15px;
      + p {
        margin: 1em 0;
      }
      iframe {
        width: 500px;
      }
    }
    ul {
      padding: 0 15px;
      list-style: disc inside;
      &.interview_photo {
        list-style: none;
        margin: 0 auto 50px auto;
        li {
          display: inline-block;
          margin: 0;
          padding: 4px;
        }
        &.before_after {
          margin-bottom: 20px;
          li {
            margin-right: 0;
            padding: 8px;
            img {
              margin: 0 auto;
              display: block;
            }
            p {
              margin-left: 0;
              text-align: center;
            }
            h3 {
              margin: 0 auto 1em auto;
              display: block;
              text-align: center;
              line-height: 35px;
            }
          }
        }
      }
      li {
        font-size: 18px;
        margin: 1em 0;
        span {
          margin-top: 5px;
          padding-left: 1em;
          display: block;
        }
        p {
          padding: 0;
          margin-left: 1em;
          + p {
            margin: 0.5em 0 0.5em 1em;
          }
        }
      }
    }
    em {
      font-style: normal;
      font-size: 15px;
    }
  }
  ul.postlist.latest-articles {
    margin: 0 0 30px 0;
    li {
      width: 730px;
      height: 250px;
      font-size: 15px;
      margin-bottom: 30px;
      padding-bottom: 30px;
      border-bottom: 2px dotted #9e9e9f;
      position: relative;
      box-sizing: content-box;
      a {
        width: 730px;
        height: 250px;
        display: block;
        &:hover {
          transition: 0.7s;
          opacity: 0.7;
        }
      }
      h2 {
        font-size: 18px;
        margin-left: 395px;
        line-height: 24px;
      }
      p.day {
        font-size: 14px;
        margin-left: 395px;
        line-height: 100%;
        margin-top: 10px;
        margin-bottom: 30px;
      }
      div.article {
        width: 335px;
        font-size: 15px;
        line-height: 24px;
        margin-left: 395px;
        overflow: hidden;
      }
    }
  }
  div.archive {
    margin: 30px 0;
    padding-bottom: 30px;
    border-bottom: 2px dotted #9e9e9f;
    font-size: 0;
    ul {
      width: 50%;
      display: inline-block;
      vertical-align: top;
      padding: 0 50px;
    }
  }
}

ul.archive_list li {
  font-size: 14px;
  line-height: 24px;
  text-decoration: underline;
  padding-left: 1em;
  position: relative;
  &:before {
    content: "";
    width: 5px;
    height: 5px;
    display: block;
    background: #231815;
    border-radius: 50%;
    position: absolute;
    top: 9px;
    left: 0;
  }
  &.start {
    font-size: 20px;
    font-weight: bold;
    text-decoration: none;
    padding-left: 0;
    margin-bottom: 0.5em;
    &:before {
      content: none;
    }
  }
}

div#wrapper {
  ul.postlist.latest-articles li {
    img {
      position: absolute;
      top: 0;
      left: 0;
    }
    div.bt {
      width: 165px;
      line-height: 25px;
      text-align: center;
      font-size: 15px;
      margin: 0;
      padding: 0;
      position: absolute;
      bottom: 30px;
      right: 0;
      color: #5c070c;
      border: 2px solid #5c070c;
    }
    a:hover div.bt {
      background: #5c070c;
      color: #FFF;
      transition: 0.7s;
    }
  }
  div {
    &.wp-pagenavi {
      line-height: 100%;
      font-size: 0;
      margin-bottom: 50px;
      text-align: right;
      span {
        display: inline-block;
        font-size: 14px;
        &.pages {
          color: #9f4448;
          font-weight: bold;
          padding: 15px 10px;
          border: 2px solid #9f4448;
          margin-right: 10px;
        }
        &.current {
          background: #9f4448;
          color: #fff;
          font-weight: bold;
          margin-right: 10px;
          padding: 15px 10px;
          border: 2px solid #9f4448;
        }
      }
      a {
        &.previouspostslink {
          border: 2px solid #9f4448;
          color: #9f4448;
          font-weight: bold;
          margin-right: 10px;
          padding: 15px 10px;
          display: inline-block;
          font-size: 14px;
          vertical-align: top;
        }
        &.page {
          &.smaller, &.larger {
            border: 2px solid #9f4448;
            color: #9f4448;
            font-weight: bold;
            margin-right: 10px;
            padding: 15px 10px;
            display: inline-block;
            font-size: 14px;
            vertical-align: top;
          }
        }
        &.nextpostslink {
          border: 2px solid #9f4448;
          color: #9f4448;
          font-weight: bold;
          margin-right: 10px;
          padding: 15px 10px;
          display: inline-block;
          font-size: 14px;
          vertical-align: top;
        }
        &.previouspostslink, &.nextpostslink {
          font-size: 10px;
        }
        &.previouspostslink:hover {
          background: #9f4448;
          color: #fff;
        }
        &.page {
          &.smaller:hover, &.larger:hover {
            background: #9f4448;
            color: #fff;
          }
        }
        &.nextpostslink:hover {
          background: #9f4448;
          color: #fff;
        }
      }
    }
    &.comment {
      border: 1px solid #5c070c;
      color: #5c070c;
      margin-bottom: 20px;
      padding: 10px 0;
      text-align: center;
    }
  }
  &.shourei h1 {
    font-size: 25px;
    line-height: 35px;
    font-weight: bold;
    color: #FFF;
    background: #5c070c;
    padding: 15px;
    border-radius: 10px;
  }
}

/*!開閉コメント*/

/*!症例*/

br.wp_social_bookmarking_light_clear {
  display: none;
}

div {
  &#wrapper.shourei {
	  
	ul.seiritsu {
		li.last {
			width: 460px;
			position: static;
			height: 40px;
			line-height: 40px;
			background: url(../images/section_icon.gif) no-repeat left center;
			padding-left: 30px;
			border-bottom: 2px dotted #9e9e9f;
		}
	}
	  
    p {
      &.update {
        line-height: 100%;
        display: inline-block;
        margin: 10px 0 0 0;
        span {
          display: inline-block;
          margin-left: 0.5em;
        }
      }
      &.category {
        display: inline-block;
        margin: 0 0 0 0.5em;
        a {
          color: #5c070c;
          display: inline-block;
          margin: 0 5px;
        }
      }
    }
    div {
      &.main_img {
        width: 730px;
        margin: 20px auto;
        display: block;
        img {
          width: 100%;
          height: auto;
          float: none;
          margin: 0;
        }
      }
      &.topic {
        margin-bottom: 30px;
        p {
          font-size: 18px;
          line-height: 30px;
          margin: 0;
          strong {
            color: #5c070c;
            font-weight: bold;
            border-bottom: 2px solid #5c070c;
            padding-bottom: 5px;
          }
        }
      }
    }
    h2 {
      line-height: 50px;
      color: #FFF;
      font-size: 20px;
      font-weight: normal;
      background: #5c070c;
      padding-left: 15px;
      margin-top: 30px;
      margin-bottom: 20px;
      &.patient {
        background: url(../images/midashi_icon.png) no-repeat 15px 5px, #809938;
        -webkit-background-size: 26px 49px;
        background-size: 26px 49px;
        border-radius: 8px 8px 0 0;
        padding-left: 50px;
        overflow: hidden;
        clear: both;
      }
    }
    p img {
      float: left;
      margin-right: 20px;
      margin-bottom: 20px;
      &.teacher_img {
        float: right;
        margin-left: 20px;
        margin-right: 0;
      }
    }
    img.wp-image-2077 {
	    width: 100%;
	    height: auto;
	    float: none;
	    margin: 0;
    }
    img.wp-image-2103 {
	    width: 100%;
	    height: auto;
	    float: none;
	    margin: 0;
	    margin-bottom: 50px;
    }
    ul {
      margin: 20px 0;
      position: relative;
      li {
        width: 460px;
        font-size: 14px;
        font-weight: bold;
        height: 40px;
        line-height: 40px;
        background: url(../images/section_icon.gif) no-repeat left center;
        padding-left: 30px;
        border-bottom: 2px dotted #9e9e9f;
        &.start {
          border-top: 2px dotted #9e9e9f;
        }
        &.last {
          position: absolute;
          top: 0;
          right: 0;
          background: none;
          border-bottom: none;
          padding-left: 0;
          width: auto;
          height: auto;
        }
      }
      &.zensoku , &.nekoze2 {
	      li {
		      &.last {
			      width: 460px;
			      font-size: 14px;
			      font-weight: bold;
			      height: 40px;
			      line-height: 40px;
			      background: url(../images/section_icon.gif) no-repeat left center;
			      padding-left: 30px;
			      border-bottom: 2px dotted #9e9e9f;
			      position: static;
		      }
	      }
      }
      &.w_style {
        margin: 10px 0;
        width: auto;
        padding: 0;
        list-style: disc inside;
        height: 30px;
        li {
          width: auto;
          height: 30px;
          background: none;
          line-height: 30px;
          float: left;
          border: none;
          padding-left: 1em;
          margin-right: 1em;
          font-size: 16px;
          font-weight: bold;
          color: #76161b;
          &.last {
            position: static;
          }
        }
      }
      &.sports_pic {
	      font-size: 0;
	      li {
		      width: calc((100% - 10px)/2);
		      height: auto;
		      display: inline-block;
		      margin-right: 10px;
		      margin-bottom: 10px;
		      vertical-align: top;
		      border: none !important;
		      background: none;
		      padding: 0;
		      img {
			      width: 100%;
			      height: auto;
		      }
		      &:nth-child(2n) {
			      margin-right: 0;
		      }
		      &:nth-child(n + 3) {
			      margin-bottom: 0;
		      }
		      &.last {
			      position: static;
		      }
	      }
      }
      &.hard_voice li {
        width: auto;
        font-size: 14px;
        font-weight: normal;
        height: auto;
        line-height: 24px;
        background: none;
        padding: 10px 10px 10px 30px;
        border: none;
        background: #eee;
        border-radius: 5px;
        margin-bottom: 10px;
        position: relative;
        &:before {
          content: "";
          width: 5px;
          height: 5px;
          display: block;
          background: #231518;
          border-radius: 50%;
          position: absolute;
          top: 19px;
          left: 15px;
        }
        &.last {
          position: relative;
        }
      }
      &.kounenki {
	      li {
		      width: 100%;
		      &.last {
			      background: url(../images/section_icon.gif) no-repeat left center;
			      padding-left: 30px;
			      border-bottom: 2px dotted #9e9e9f;
			      position: static;
		      }
	      }
      }
    }
	img.wp-image-1966 {
	float: none;
	margin: 0;
	}
    
    div.help {
      margin: 20px auto 30px auto;
      text-align: center;
      font-family: "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
      h3 {
        font-size: 34px !important;
        line-height: 45px;
        color: #5c070c;
        font-weight: lighter;
        margin: 0;
        padding: 0;
        border: none;
      }
      p {
        font-size: 24px;
        line-height: 33px;
        margin: 0;
      }
    }
    h3 {
      font-size: 18px;
      line-height: 30px;
      margin: 20px 0 10px 0;
      color: #5c070c;
      border: 1px solid #5c070c;
      padding: 0 10px;
      &.num01, &.num02, &.num03, &.num04 {
        margin: 20px 0;
        padding-right: 0;
        font-size: 32px;
        font-weight: lighter;
        font-family: "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
        border: none;
      }
      &.num01.toppatsu, &.num02.toppatsu, &.num03.toppatsu {
        line-height: 90px;
      }
      &.num01 {
        background: url(../images/jiritsu_num01.png) no-repeat left center;
        padding-left: 95px;
        line-height: 45px;
      }
      &.num02 {
        background: url(../images/jiritsu_num02.png) no-repeat left center;
        padding-left: 95px;
        line-height: 45px;
      }
      &.num03 {
        background: url(../images/jiritsu_num03.png) no-repeat left center;
        padding-left: 95px;
        line-height: 45px;
      }
      &.num04 {
        background: url(../images/jiritsu_num04.png) no-repeat left center;
        -webkit-background-size: 85px 85px;
        background-size: 85px 85px;
        padding-left: 95px;
      }
      &.num01.toppatsu, &.num02.toppatsu, &.num03.toppatsu, &.num04.toppatsu {
        line-height: 90px;
      }
    }
    p {
      line-height: 25px;
      margin: 0.5em 0;
      strong {
        color: #5c070c;
      }
    }
    dl.point {
      margin: 20px 0 20px 10px;
    }
    dt {
      display: list-item;
      list-style: disc inside;
      font-size: 14px;
      font-weight: bold;
      line-height: 20px;
      padding-left: 2px;
      color: #717071;
      span {
        color: #231815;
      }
    }
    dd {
      font-size: 14px;
      font-weight: bold;
      line-height: 20px;
      margin-bottom: 1em;
      position: relative;
      padding-left: 15px;
      &:before {
        content: "▶︎";
        font-size: 8px;
        color: #717071;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    ul {
      &.figure {
        font-size: 0;
        li {
          width: 352px;
          height: auto;
          line-height: 0;
          font-size: 0;
          background: none;
          border: none;
          padding: 0;
          display: inline-block;
          &.start {
            margin: 0 25px 10px 0;
          }
          &.last {
            position: static;
            margin: 0 0 0 25px;
          }
        }
      }
      &.shoujou {
        margin: 10px 0;
        list-style: disc inside;
        li {
          width: 100%;
          height: auto;
          background: none;
          font-size: 18px;
          padding-left: 1em;
          border-bottom: none;
          &.start {
            border-top: none;
          }
        }
      }
      &.note li.start {
        border-top: none;
      }
      &.shoujou li.last, &.note li.last {
        position: static;
      }
      &.shoujou li span {
        font-weight: normal;
        font-size: 14px;
        line-height: 25px;
        display: block;
        padding-left: 1em;
        &.note {
          color: #5c070c;
          border: 1px solid #5c070c;
          font-size: 13px;
          font-weight: normal;
          line-height: 20px;
          display: block;
          padding: 10px;
          margin: 5px 15px;
          text-indent: 0;
        }
      }
    }
    h4 {
      margin: 10px 0;
      font-size: 15px;
      color: #5c070c;
      line-height: 25px;
      span {
        display: block;
        line-height: 100%;
        font-size: 11px;
        font-weight: normal;
      }
    }
    h5 {
      margin: 5px 0;
      padding: 0;
      font-size: 14px;
    }
    ul.note {
      margin: 10px 0;
      color: #5c070c;
      border: 1px solid #5c070c;
      li {
        width: 100%;
        height: auto;
        font-size: 13px;
        font-weight: normal;
        background: none;
        line-height: 20px;
        padding: 10px 10px 10px 2em;
        text-indent: -1em;
        border-bottom: none;
        &.start {
          padding-bottom: 0;
        }
        &.last {
          padding-top: 5px;
        }
      }
    }
    a u {
      color: blue;
      text-decoration: none;
      font-weight: bold;
    }
    ul.cause li span a, p a {
      display: inline-block;
      color: blue;
      text-decoration: underline;
    }
    span.note {
      color: #5c070c;
      border: 1px solid #5c070c;
      font-size: 13px;
      line-height: 20px;
      padding: 10px;
      text-indent: -1em;
      padding-left: 2em;
      margin: 10px;
      display: inline-block;
    }
    div.box {
      padding: 1em;
      margin: 10px;
      color: #5c070c;
      border: 1px solid #5c070c;
      display: inline-block;
      b {
        font-size: 15px;
        line-height: 30px;
        display: block;
        color: #5c070c;
      }
      span {
        color: #231815;
        line-height: 25px;
      }
    }
    ol {
      margin: 10px;
      padding: 10px;
      border: 1px solid #5c070c;
      li {
        font-size: 18px;
        font-weight: bold;
        color: #5c070c;
        list-style: decimal inside;
        margin-bottom: 10px;
        &.last {
          margin-bottom: 0;
        }
        span {
          height: 25px;
          line-height: 25px;
          display: block;
          padding-left: 30px;
          margin-left: 2em;
          font-size: 13px;
          font-weight: normal;
          color: #231815;
          position: relative;
        }
        b {
          height: 25px;
          line-height: 25px;
          display: block;
          font-size: 15px;
          color: #5c070c;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          margin: auto;
        }
      }
      &.cause {
        border: none;
        padding: 0;
        margin: 20px 0;
        list-style: decimal inside;
        li {
          font-size: 16px;
          color: #231815;
          margin-bottom: 10px;
          padding: 0 10px;
          span {
            height: auto;
            display: block;
            margin: 5px 0 0 1.5em;
            padding: 0;
          }
        }
      }
    }
    ul.cause {
      list-style: disc inside;
      li {
        width: auto;
        height: auto;
        font-size: 16px;
        font-weight: bold;
        line-height: 30px;
        background: none;
        margin: 0 1em;
        padding-left: 0;
        border-bottom: none;
        span {
          display: block;
          margin: 5px 0 1em 1em;
          padding: 0;
          line-height: 23px;
          font-size: 14px;
          font-weight: normal;
          &.caption {
            margin-top: 0;
            line-height: 100%;
            font-size: 12px;
          }
        }
        &.start {
          border-top: none;
        }
        &.last {
          position: static;
        }
      }
    }
    p span {
      text-indent: -1em;
      padding-left: 2em;
      line-height: 20px;
      display: block;
    }
  }
  &#footer {
    width: 100%;
    &.info {
      .inner {
        background: url(../images/footer_img.png) no-repeat right 190px;
        padding: 30px 0;
      }
      background: #e5e5e6;
      div.map {
        height: 292px;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        left: 0;
      }
      a#tel {
        margin-left: 500px;
        width: 391px;
        height: 47px;
        display: block;
      }
      p, table {
        font-size: 14px;
        margin-left: 500px;
      }
      p {
        &.add {
          margin-top: 14px;
          margin-bottom: 10px;
          font-size: 16px;
        }
        &.info {
          &:before {
            content: "受付時間";
            border: 1px solid #231815;
            margin-right: 5px;
            display: inline-block;
            width: 70px;
            text-align: center;
          }
          span {
            display: block;
            margin-left: 77px;
          }
        }
        &.day {
          margin-top: 5px;
          &:before {
            content: "休診日";
            border: 1px solid #231815;
            margin-right: 5px;
            display: inline-block;
            width: 70px;
            text-align: center;
          }
        }
        &.sub {
          font-size: 12px;
        }
      }
      table {
        width: 380px;
        height: 120px;
        margin-top: 5px;
        margin-bottom: 5px;
        border: 1px solid #3e3a39;
        background: #fff;
        text-align: center;
        th {
          background: #e5cfd1;
          border-right: 1px solid #3e3a39;
          &.start {
            width: 70px;
          }
          &.last {
            border-right: none;
          }
        }
        td {
          border-top: 1px solid #3e3a39;
          border-right: 1px solid #3e3a39;
          &.care {
            width: 90px;
          }
          &.last {
            border-right: none;
          }
        }
      }
    }
    &.menu {
      background: #231815;
      padding: 20px 0;
      color: #fff;
      a#logo {
        width: 246px;
        height: 80px;
        display: block;
      }
      ul {
        width: 710px;
        text-align: right;
        position: absolute;
        top: 10px;
        right: 0;
        li {
          font-size: 12px;
          line-height: 100%;
          border-right: 1px solid #fff;
          display: inline-block;
          padding: 0 0.5em;
          margin-bottom: 9px;
        }
      }
      p {
        font-size: 11px;
        line-height: 100%;
        position: absolute;
        bottom: 10px;
        right: 0;
      }
    }
  }
  &.ninja_onebutton {
    margin: 5px;
  }
}

/*!footer*/

.ninja_onebutton_output > div {
  padding: 0 !important;
  margin: 5px !important;
}

.ninja_onebutton_output_horizontal div {
  display: inline-block !important;
  vertical-align: bottom !important;
  float: none !important;
}

ul.meniere_li {
	list-style: disc inside !important;
	
	li {
		width: 100% !important;
		height: 30px;
		line-height: 30px;
		font-size: 18px !important;
		border: none !important;
		background: none !important;
		padding-left: 10px !important;
		
		strong {
			color: #60310d;
		}
		
		&.last {
			position: static !important;
		}
	}
}

div.stage {
	border: 1px solid #231815;
	padding: 5px;
}

div#wrapper.shourei div.imgps07.meniere {
	
	ul {
		li {
			width: 50%;
			height: auto;
			display: inline-block;
			border: none;
			padding-left: 0;
			background: none;
			
			&.last {
				position: static;
			}
			
			img {
				position: static;
				margin: 0 auto 20px auto !important;
				display: block;
			}
			
		}
	}
	
}

strong.stretch {
	font-size: 18px;
	line-height: 34px;
	color: #60310d !important;
}

div.nekoze_price {
	font-size: 25px;
	font-weight: bold;
	margin-top: 15px;
	
	span {
		font-size: 20px;
		font-weight: bold;
		color: #fff;
		display: inline-block;
		background: #6da03c;
		padding: 5px 10px;
		margin-right: 15px;
	}
	
	i {
		font-size: 16px;
		font-style: normal;
	}
}

ul.nekoze {
	
	li {
		width: 50% !important;
		height: 186px !important;
		font-size: 14px !important;
		line-height: 24px !important;
		position: relative;
		padding: 40px 0 0 150px !important;
		border-bottom: none !important;
		margin-bottom: 20px;
		display: inline-block;
		vertical-align: top;
		background: none !important;
		
		&.start {
			border-top: none !important;
		}
		
		&.last {
			position: relative !important;
		}
		
		img {
			position: absolute;
			top: 0;
			left: 0;
		}
		
		p {
			font-size: 20px;
			line-height: 40px !important;
			font-weight: bold;
			color: #5c070c;
			display: list-item;
			margin: 0 !important;
			
			&+p {
				list-style: none;
				display: inline-block;
			}
			
		}
		
		span {
			font-size: 14px;
			line-height: 24px !important;
			font-weight: normal;
			list-style: none;
			text-indent: 0 !important;
			padding-left: 0 !important;
		}
	}
		
}

div.meniere {
	position: relative;
	
	p {
		width: 460px;
		
		&.meniere_p {
			border: 1px dotted #60310d;
			padding: 5px;
		}
		
	}
	
	img {
		position: absolute;
		top: 0;
		right: 0;
		float: none !important;
		margin-right: 0 !important;
	}
	
}



@media screen and (max-width: 767px) {
  /*!共通*/
  *:hover, a:hover img {
    opacity: 1 !important;
  }
  .inner {
    width: auto;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
  }
  img {
    width: 100%;
    height: 100%;
  }
  .sp {
    display: block !important;
  }
  .pc {
    display: none !important;
  }
  p {
    word-wrap: break-word;
  }
  
  /*!2016更新*/
  
  div#container {
	  
	  &.home {
		  height: 100%;
	  }
	  
	  .flexslider , .slides , .slides li {
		height: 100% !important;
		  
	    div.main.inner {
	  	  height: 100% !important;
	  	  z-index: 10;
	  	  
	  	  h1 {
		  	  width: 287px;
		  	  padding: 0;
		  	  margin-left: 10px;
		  	  bottom: 20px;
		  	  
		  	  img {
			  	  width: 287px;
			  	  height: auto;
			  	  margin: 0;
		  	  }
		  	  
	  	  }
	  	  
	    }
	    
	  }
	  
	  ul.slides {
		  
		  li {
			  &.img01 {
				  background: url(../images/slide01_sp.jpg) center center;
				  background-size: cover;
			  }
			  &.img02 {
				  background: url(../images/slide02_sp.jpg) center center;
				  background-size: cover;
			  }
			  &.img03 {
				  background: url(../images/slide03_sp.jpg) center center;
				  background-size: cover;
			  }
			  &.img04 {
				  background: url(../images/slide04_sp.jpg) center center;
				  background-size: cover;
			  }
			  &.img05 {
				  background: url(../images/slide05_sp.jpg) center center;
				  background-size: cover;
			  }
		  }
		  
	  }
	  
	  div#header {
		  
		  div.inner {
		  	
		  	a#logo {
			  	width: 77px;
			  	height: 25px;
			  	margin: 5px 0 5px 10px;
		  	}
		  	
		    a.sp_tel02.sp {
			  width: 176px;
			  height: 22px;
		  	  display: block !important;
		  	  position: absolute;
		  	  top: 3px;
		  	  right: 50px;
		  	  margin: auto;
		    }
		    
		    div.sp_bt.sp {
			    width: 25px;
			    height: 25px;
			    top: 2px;
			    display: block !important;
		    }
		    
		  }
		  
	  }
	  
      a.sp_tel.sp {
  	    display: none !important;
      }
	  
  }
  
  div.top {
  
	  div#wrapper {
		  margin-top: 10px;
		  
		  div.kuchikomi.sp {
			  
			  p {
				  padding: 0 10px;
			  }
			  
			  img {
				  display: block;
				  margin-bottom: 10px;
			  }
		  }
		  
		  h3.sp {
			  margin: 20px 0 0 0;
			  padding: 10px;
			  background: #5c070c;
			  color: #fff;
			  font-size: 1.1em;
			  font-weight: bold;
			  text-align: center;
			  line-height: 100%;
			  
			  span {
			  	font-size: 0.7em;
			  	margin-bottom: 5px;
			  	display: block;
			  }
			  
		  }
		  
		  ul.sp_img.sp {
			  margin: 15px 0;
			  padding: 0 10px;
			  text-align: center;
			  font-size: 0;
			  
			  li {
				  width: calc((100% - 10px)/3);
				  display: inline-block;
				  margin-bottom: 5px;
				  background: none;
				  min-height: 0;
				  line-height: 1;
				  padding: 0;
				  border: 1px solid #595757;
				  border-radius: 3px;
				  
				  &:nth-of-type(2) , &:nth-of-type(5) {
					  margin: 0 5px;
				  }
				  
			  }
			  
		  }
		  
		  div.tokuten {
			  margin: 10px 0 15px 0;
			  padding: 0 10px;
			  a {
				  width: 100%;
				  height: auto;
				  display: block;
			  }
		  }
		  
		  div.media {
			  padding: 0;
			  
			  h2 {
				  font-size: 1.1em;
				  line-height: 1.5;
				  
				  span {
					  font-size: 0.7em;
					  line-height: 1.5;
					  padding: 0;
				  }
				  
			  }
			  
			  ul.movie {
				  margin-top: 0;
				  margin-bottom: 0;
				  
				  li {
					  padding: 10px;
					  margin-bottom: 0;
					  
					  &.last {
						  margin-right: 0;
						  padding-top: 0;
					  }
					  
				  }
				  
			  }
			  
			  p.radio {
			    margin-bottom: 15px;
			    line-height: 1;
			    
			  }
			  
			  div.radio {

				  h2 {
					  span {
						  &:before {
							  top: auto;
							  right: 5px;
							  bottom: -55px;
							  left: auto;
						  }
					  }
				  }
				  
				  hr {
				  	margin-bottom: 15px;
				  }
				  
				  audio {
					  margin-top: 10px;
				  }
			  }
			  
		  }
		  
		  div.profile_photo {
		  	padding: 15px 0;
		  	
		  	hr {
			  margin-bottom: 15px;
		  	}
		  	
		  	ul.person {
			  	text-align: center;
			  	padding: 0 10px;
			  	margin-bottom: 0;

			  	li {
				  	width: calc((100% - 20px)/3);
				  	font-size: 11px;
				  	display: inline-block;
				  	vertical-align: top;
				  	text-align: center;
				  	border-top: none;
				  	padding: 0;
				  	margin: 0;
				  	background: none;
				  	
				  	&.start , &:nth-of-type(2) {
					  	br {
						  	display: block;
					  	}
				  	}
				  	
				  	&:nth-of-type(2) , &:nth-of-type(5) {
					  	margin: 0 10px;
				  	}
				  	
				  	br {
					  	display: none;
				  	}
				  	
				  	img {
					  	width: 100%;
					  	height: auto;
				  	}
				  	
				  	p {
					  	height: auto;
					  	font-size: 12px;
					  	line-height: 1.5;
					  	padding: 0;
					  	margin-top: 10px;
					  	position: static;
				  	}
				  	
			  	}
		  	}
		  	
		  }
		  .ebook {
			  width: calc(100% - 15px);
			  margin: 30px auto 0 auto;
			  padding: 0 10px;
		  }
		  div.media_bana {
			  margin-top: 5px;
			  
			  h2.sp {
				  padding: 0 10px;
				  margin: 0 0 5px 0;
				  line-height: 1;
				  background: none;
			  }
			  
			  p {
				  font-size: 12px;
				  line-height: 16px;
				  font-weight: normal;
				  
				  &+p {
					  margin-bottom: 15px;
				  }
				  
			  }
			  
			  div.inner.sp {
				  background: url(../images/wall.jpg);
				  -webkit-background-size: 200px 200px;
				  background-size: 200px 200px;
				  font-size: 0;
				  padding: 10px;
			  }
			  
			  ul.media_img {
				  width: calc((100% - 10px)/2);
				  display: inline-block !important;
				  vertical-align: top;
				  background: none;
				  padding: 0;
				  
				  li {
					  font-size: 12px;
					  line-height: 14px;
					  margin-bottom: 10px;
					  padding: 0;
					  
					  &.last {
						  margin-bottom: 0;
					  }
					  
					  a {
						  padding: 10px;
						  
						  &:after {
							  height: 25px;
							  line-height: 25px;
							  margin-top: 5px;
						  }
						  
					  }
					  
				  }
				  
				  &.left {
					  margin-right: 10px;
				  }
				  
				  &.pc {
					  display: none !important;
				  }
				  
				  
			  }
			  
		  }
		    
	      div.tel {
	    	  margin-top: 0;
	      }
		  
		  div.voice_pickup {
			  margin: 15px 0;
			  
			  h2.interview {
				  line-height: 22px;
				  margin: 30px 0 10px 0;
			  }
			  
			  p {
				  font-size: 12px;
				  line-height: 16px;
				  font-weight: normal;
				  padding: 0 10px;
			  }
			  
			  a.interview {
				  margin: 15px 0;
			  }
			  
			  a.interview_bt {
				  margin-bottom: 10px;
			  }
			  
			  ul.voicelist {
				  margin-top: 10px;
				  
				  h3 {
					  font-size: 14px;
					  line-height: 20px;
					  font-weight: normal;
				  }
				  
			  }
		  }
		  
		  div.profile_photo.diploma {
			  
			  ul.award {
				  margin-top: 15px;
				  
				  li {
					  p {
						  font-size: 11px;
						  line-height: 1;
					  }
					  
					  &:nth-of-type(3) , &.last {
						  margin-bottom: 0;
					  }
				  }
				  
			  }
			  
		  }
		  
		  ul.sp_nav {
			  margin-top: 15px;
			  
			  li {
				  margin-bottom: 15px;
				  position: relative;
				  font-size: 0;
				  
				  &.last {
					  margin-bottom: 5px;
				  }
				  
				  a {
					  width: 100%;
					  height: 100%;
					  display: block;
					  position: absolute;
					  top: 0;
					  left: 0;
				  }
				  
				  h3 {
					  height: 40px;
					  font-size: 16px;
					  font-weight: bold;
					  color: #fff;
					  line-height: 40px;
					  background: #5c070c;
					  padding-left: 10px;
					  position: relative;
				  }
				  
				  img {
				      width: 50%;
				      height: 50%;
				      padding: 10px;
				      display: inline-block;
				      vertical-align: top;
				  }
				    
				  p {
					  width: 50%;
				  	  font-size: 14px;
				  	  line-height: 24px;
				  	  padding: 10px 10px 10px 0;
				  	  display: inline-block;
				  	  vertical-align: top;
				  }
				    
				  span {
				  	  width: 145px;
				  	  height: 30px;
				  	  font-size: 12px;
				  	  line-height: 30px;
				  	  display: block;
				  	  color: #fff;
				  	  background: #76161b;
				  	  text-align: center;
				  	  position: absolute;
				  	  bottom: 10px;
				  	  right: 10px;
				  }
				  
			  }
			  
		  }
		  
	  }
	  
	    div.ft_img {
	  	  margin: 15px 0 0 0;
	  	  
	  	  p {
	  		  height: 40px;
	  		  font-size: 11px;
	  		  line-height: 40px;
	  		  text-align: center;
	  		  color: #fff;
	  		  margin-top: 15px;
	  		  background: #231815;
	  	  }
	  	  
	    }
	  
  }
  
  /*!header*/
  div#header {
    width: 100%;
    height: auto;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1001;
    div.inner {
      width: auto !important;
      height: auto !important;
      margin: 0 auto;
      padding: 0;
      overflow: visible;
    }
    a#logo {
      width: 100px;
      height: auto;
      display: block;
      margin: 14px 0 13px 10px;
    }
  }
  a.sp_tel {
    height: 70px;
    padding: 10px;
    position: absolute;
    top: 60px;
    z-index: 1000;
    left: 0;
    right: 0;
    margin: 10px;
    background: rgba(255, 255, 255, 0.7);
    color: #5c070c;
    border: 2px solid #5c070c;
    border-radius: 10px;
    
    &:before {
	    content: "";
	    width: 7px;
	    height: 7px;
	    display: block;
	    border-top: 2px solid #5c070c;
	    border-right: 2px solid #5c070c;
	    position: absolute;
	    top: 0;
	    right: 15px;
	    bottom: 0;
	    margin: auto;
	    transform: rotate(45deg);
	    -webkit-transform: rotate(45deg);
	    -moz-transform: rotate(45deg);
	    -o-transform: rotate(45deg);
	    -ms-transform: rotate(45deg);
	}
    
    img {
      width: 265px;
      height: auto;
      display: block;
    }
  }
  
  a.bt.sp {
	  height: 50px;
	  font-size: 14px;
	  line-height: 50px;
	  text-align: center;
	  color: #fff;
	  background: #5c070c;
	  margin: 0 15px 20px 15px;
	  position: relative;
	  
	  &:before {
	    content: "";
	    width: 7px;
	    height: 7px;
	    display: block;
	    border-top: 2px solid #fff;
	    border-right: 2px solid #fff;
	    position: absolute;
	    top: 0;
	    right: 15px;
	    bottom: 0;
	    margin: auto;
	    transform: rotate(45deg);
	    -webkit-transform: rotate(45deg);
	    -moz-transform: rotate(45deg);
	    -o-transform: rotate(45deg);
	    -ms-transform: rotate(45deg);
	  }
	  
  }
  
  div {
    &#header div {
      &.sp_bt {
        width: 30px;
        height: 14px;
        position: absolute;
        top: 10px;
        right: 10px;
        display: block;
        font-size: 0;
        &:hover {
          cursor: pointer;
        }
      }
      &.menu {
	    width: 100%;
        height: 100%;
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.9);
        padding-top: 10px;
        overflow: scroll;
        -webkit-overflow-scrolling: touch;
        z-index: 1;
      }
      &.sp_tel {
        padding: 20px 10px;
        display: block;
        a {
          width: 100%;
          margin: 0 auto;
          display: block;
        }
      }
      &.consul {
        position: static;
        padding: 0 10px;
        p {
          font-size: 12px;
          line-height: 20px;
          margin: 0 auto;
          display: block;
          position: relative;
          &:before {
            content: none !important;
          }
          + p {
            margin-top: 10px;
          }
          &.week:before {
            margin-left: 0;
          }
          br {
            display: block;
          }
          span.sp {
            line-height: 25px;
            margin: 0 0 5px 0;
            padding: 0;
            display: block;
            text-align: center;
            background: #FFF;
            color: #231815;
            font-size: 14px;
            font-weight: bold;
            border: none;
          }
        }
      }
    }
    &.sns {
      position: static;
      font-size: 0;
      text-align: center;
      padding: 15px 0 20px 0;
      a {
        &#twitter, &#facebook {
          width: 34px;
          height: 34px;
          display: inline-block;
        }
        &#twitter {
          margin-right: 10px;
          background: url(../images/sp_twitter_icon.png) no-repeat;
          background-size: 34px 34px;
        }
        &#facebook {
          background: url(../images/sp_facebook_icon.png) no-repeat;
          background-size: 34px 34px;
        }
        &:hover {
          opacity: 0.5;
          transition: 0.7s;
        }
      }
    }
  }
  .flex-control-nav {
    bottom: 30px !important;
    left: auto;
    right: 10px !important;
  }
  .flex-control-paging li a {
    background: rgba(0, 0, 0, 0.5) !important;
    &:hover {
      background: rgba(0, 0, 0, 0.5) !important;
      background: rgba(92, 6, 11, 0.7) !important;
    }
    &.flex-active {
      background: rgba(0, 0, 0, 0.5) !important;
      background: rgba(92, 6, 11, 0.9) !important;
      cursor: default;
    }
  }
  /*!nav*/
  div {
    &#header ul.nav {
      width: 100%;
      height: 100%;
      text-align: center;
      display: list-item;
      margin: 0 0 20px;
      position: relative;
      li {
        height: 50px;
        line-height: 50px;
        display: block;
        font-size: 16px;
        border-left: none;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        text-align: left;
        &.bt , &.start {
          border-bottom: none;
          position: absolute;
          top: 0px;
          right: 10px;
          line-height: 0;
          padding: 10px;
          img {
            margin: 0 auto;
            display: block;
            width: 25px;
            height: 25px;
          }
        }
        &.last {
          border-right: none;
        }
        a {
          height: 50px;
          &:hover {
            transition: none;
            background: none;
          }
        }
        img {
          display: inline-block;
          width: 24px;
          height: auto;
          padding-top: 0;
          margin: 0 10px;
          vertical-align: middle;
        }
      }
    }
    &#container {
      &.home {
        overflow: visible;
      }
      &.price {
        height: 300px;
        background: url(../images/price.jpg) no-repeat center center;
        background-size: cover;
        position: relative;
      }
      &.profile {
        height: 300px;
        background: url(../images/profile.jpg) no-repeat center top;
        background-size: cover;
        position: relative;
      }
      &.readme {
        height: 300px;
        background: url(../images/readme.jpg) no-repeat center center;
        background-size: cover;
        position: relative;
      }
      &.question {
        height: 300px;
        background: url(../images/question.jpg) no-repeat center center;
        background-size: cover;
        position: relative;
      }
      &.access {
        height: 300px;
        background: url(../images/access.jpg) no-repeat center top;
        background-size: cover;
        position: relative;
      }
      &.flow {
        height: 300px;
        background: url(../images/flow.jpg) no-repeat center center;
        background-size: cover;
        position: relative;
      }
      &.voice {
        height: 300px;
        background: url(../images/voice.jpg) no-repeat center center;
        background-size: cover;
        position: relative;
      }
      &.contact {
        height: 300px;
        background: url(../images/contact.jpg) no-repeat center center;
        background-size: cover;
        position: relative;
      }
      &.shourei {
        height: 300px;
        background: url(../images/shourei.jpg) no-repeat center center;
        background-size: cover;
        position: relative;
      }
      &.privacy {
        height: 300px;
        background: url(../images/privacy.jpg) no-repeat center center;
        background-size: cover;
        position: relative;
      }
      &.sitemap {
        height: 300px;
        background: url(../images/sitemap.jpg) no-repeat center center;
        background-size: cover;
        position: relative;
      }
      &.jiko {
        height: 300px;
        background: url(../images/jiko.jpg) no-repeat center center;
        background-size: cover;
        position: relative;
      }
      &.nail {
        height: 300px;
        background: url(../images/nail_sp.jpg) no-repeat center center;
        background-size: cover;
        position: relative;
      }
      &.blog {
        height: 300px;
        background: url(../images/blog.jpg) no-repeat center center;
        background-size: cover;
        position: relative;
      }
      &.home div.main.inner {
        width: 100%;
        height: 300px;
        margin-left: 0;
        left: 0;
      }
      div.inner {
        width: 100%;
        height: 300px;
      }
      div {
        &.inner h1 {
          padding: 10px;
          img {
            width: auto;
            height: 30px;
            display: block;
            margin: 0 auto;
          }
        }
        &.shourei.inner h1 img {
          width: auto;
        }
      }
      &.access div.inner h1 img, &.privacy div.inner h1 img, &.nail div.inner h1 img, &.jiko div.inner h1 img {
        width: auto;
      }
      div.inner h1 span {
        display: none !important;
      }
    }
    &#wrapper {
      width: 100%;
      min-height: 0 !important;
      margin: 20px 0 0 0;
      a.campaign {
        padding: 20px 10px 0 10px;
      }
      p.campaign {
        padding: 0 10px;
      }
      a.ekiten {
        padding: 15px 10px 0 10px;
        margin-bottom: 0;
      }
      div {
        &.trouble {
          margin: 15px 0;
          h2 {
            font-size: 20px;
            font-weight: bold;
            background: #5c070c;
            padding: 0 10px;
            color: #FFF;
            line-height: 35px;
            margin-bottom: 10px;
          }
          h3 {
            font-size: 20px;
            color: #231815;
            background: #eeeceb;
            padding: 10px;
            margin: 10px;
          }
          ul {
            padding: 0 10px;
            li {
              margin-bottom: 10px;
              &.last {
                margin-bottom: 0;
              }
            }
          }
        }
        &.section01 {
          margin: 20px 0;
          h2 {
            padding: 0 10px;
            span {
              margin-top: 5px;
            }
          }
          ul {
            width: 100%;
            height: auto;
            margin: 20px 0;
            li {
              background-position: 10px;
              min-height: 40px;
              height: auto;
              line-height: 20px;
              padding: 10px 10px 10px 40px;
              &.last {
                padding: 0;
                h3 {
                  padding: 15px 10px;
                  margin-top: 20px;
                  font-size: 1.1em;
                  span {
                    font-size: 0.7em;
                    margin-bottom: 5px;
                  }
                }
              }
            }
          }
          div {
            &.greetingbox01, &.greetingbox02 {
              margin: 20px 0;
              padding: 0 10px;
            }
          }
        }
        &.imgposi01 {
          display: none;
        }
        &.greetingbox01 img {
          width: 120px;
          height: 105px;
          margin: 0 10px 10px 0;
          position: static;
          float: left;
        }
        &.greetingbox02 img {
          width: 120px;
          height: 105px;
          position: static;
          margin-left: 0;
          float: right;
        }
        &.section01 div.greetingbox01 p {
          width: 100%;
          margin-left: 0;
          line-height: 25px;
        }
        &.greetingbox02 {
          p {
            width: 100%;
            margin-left: 0;
            line-height: 25px;
          }
          span {
            margin-top: 20px;
          }
        }
        &.media {
          margin: 20px 0 0 0;
          padding: 20px 10px 0 10px;
          h2 span {
	        padding: 10px;
            margin-bottom: 5px;
            font-size: 12px;
          }
          div.radio h2 {
            position: relative;
            span {
              &:before {
                content: "";
                width: 100px;
                height: 50px;
                display: block;
                background: url(../images/fm_hirakata_logo.png) no-repeat;
                background-size: 100px 50px;
                position: absolute;
                top: -8px;
                left: 0;
              }
            }
          }
          h2 {
            padding: 10px;
            line-height: 30px;
            font-size: 22px;
            margin: 0;
          }
        }
        
        &.media_bana {
	        margin-top: 15px;
          p {
	          margin: 0 10px;
          }
          
          div.comment {
	          margin: 0 10px 15px 10px;
          }
          
          ul.media_img {
	          padding: 15px 10px;
	          
	          li {
		          width: 100%;
		          height: auto;
		          display: list-item;
		          margin: 0;
		          
		          a {
			          width: 100%;
			          height: auto;
			          padding: 0;
			          position: static;
			          
			          &:after {
				          width: 100%;
				          position: static;
				          margin-top: 15px;
			          }
			          
		          }
		          
	          }
	          
          }
        }
        &.media_bana h2 {
	      height: auto;
          margin: 0 10px;
          line-height: 30px;
          font-size: 22px;
          margin-bottom: 15px;
        }
        &.media {
          ul.movie {
            width: 100%;
            margin-bottom: 10px;
            margin-top: 15px;
            li {
              display: list-item;
              margin: 0 0 20px 0;
              text-align: center;
              &.last {
	            margin-right: 10px;
                margin-bottom: 0;
              }
              iframe {
                width: 100%;
                height: 200px;
              }
            }
          }
          p.radio {
            padding: 0 10px;
          }
          div {
            &.radio {
              width: 100%;
              audio {
                width: 100%;
                background-size: cover;
                margin: 15px 0 0 0;
              }
              p {
                display: none;
              }
            }
            &.coverage a {
              width: 100%;
              height: auto;
              margin-top: 15px;
            }
          }
        }
        &.tel {
          margin-top: 20px;
          padding: 15px 10px;
          font-weight: normal;
        }
      }
    }
    &#footer.menu div.tel.sp {
      margin-top: 20px;
      padding: 15px 10px;
      font-weight: normal;
    }
    &#wrapper {
      &.shourei div.tel {
        margin-top: 20px;
        padding: 15px 10px;
        font-weight: normal;
      }
      div.tel a#tel, &.shourei div.tel a#tel {
        width: 100%;
        height: auto;
        display: block;
        line-height: 0;
      }
      div.tel a#tel img, &.shourei div.tel img {
        height: auto;
        float: none;
        margin: 0;
      }
      div.tel p {
        text-align: left;
        margin-top: 15px;
        padding: 0;
        span {
          line-height: 25px;
          margin: 10px 0 5px 0;
          padding: 0;
          display: block;
          text-align: center;
          background: #FFF;
          color: #5c070c;
          font-size: 14px;
          font-weight: bold;
          border: none;
        }
      }
    }
    &#footer.menu div.tel.sp span {
      line-height: 25px;
      margin: 10px 0 5px 0;
      padding: 0;
      display: block;
      text-align: center;
      background: #FFF;
      color: #5c070c;
      font-size: 14px;
      font-weight: bold;
      border: none;
    }
    &#wrapper {
      &.shourei div.tel span {
        line-height: 25px;
        margin: 10px 0 5px 0;
        padding: 0;
        display: block;
        text-align: center;
        background: #FFF;
        color: #5c070c;
        font-size: 14px;
        font-weight: bold;
        border: none;
        border: none !important;
        padding: 0 !important;
        margin: 10px 0 5px 0 !important;
        display: block !important;
      }
      div.voice_pickup {
        margin: 20px 0;
        h2 {
          padding: 0 10px;
        }
      }
      a {
        &.interview, &.interview_bt {
          width: 100%;
          height: auto;
        }
      }
      div.voice_pickup ul.voicelist {
        margin-top: 20px;
        li {
          height: 130px;
          padding: 20px 10px;
          overflow: hidden;
          img {
            width: 50%;
            height: auto;
            top: 0;
            bottom: 0;
            margin: auto;
            left: 10px;
          }
          h3 {
            margin-left: 55%;
            margin-bottom: 0;
            line-height: 20px;
            background-position: left 2px;
          }
          p {
            display: none;
          }
          a {
            width: 45%;
            height: 30px;
            line-height: 30px;
            box-shadow: none;
            position: static;
            margin-top: 17px;
            margin-left: 55%;
          }
          &.last {
            height: auto;
            padding: 15px 10px 0 10px;
            margin: 0;
            a {
              width: auto;
              height: 50px;
              line-height: 50px;
              font-size: 20px;
              margin: 0;
              &:hover {
                width: auto;
                height: 50px;
              }
            }
          }
        }
      }
      dl.slidelist {
        margin: 15px 10px;
      }
      div.comment {
        font-size: 10px;
        padding: 5px;
      }
      dl.slidelist dt, div.price_section dl.price_order dt {
        min-height: 40px;
        line-height: 20px;
        font-size: 14px;
        padding: 10px 0 10px 10px;
      }
      dl {
        &.profile_list dt, &.readme_list dt, &.question_list dt, &.flow_list dt, &.voice_list dt, &.privacy_list dt, &.jiko_list dt, &.nail_list dt {
          min-height: 40px;
          line-height: 20px;
          font-size: 14px;
          padding: 10px 0 10px 10px;
        }
        &.slidelist dt:after {
          top: 0;
          bottom: 0;
          right: 10px;
          margin: auto;
          height: 17px;
        }
      }
      div.price_section dl.price_order dt:after {
        top: 0;
        bottom: 0;
        right: 10px;
        margin: auto;
        height: 17px;
      }
      dl {
        &.profile_list dt:after, &.readme_list dt:after, &.question_list dt:after, &.voice_list dt:after, &.jiko_list dt:after, &.nail_list dt:after {
          top: 0;
          bottom: 0;
          right: 10px;
          margin: auto;
          height: 17px;
        }
        &.slidelist dt:hover:after {
          right: 10px;
        }
      }
      div.price_section dl.price_order dt:hover:after {
        right: 10px;
      }
      dl {
        &.profile_list dt:hover:after, &.readme_list dt:hover:after, &.question_list dt:hover:after, &.voice_list dt:hover:after, &.jiko_list dt:hover:after, &.nail_list dt:hover:after {
          right: 10px;
        }
        &.slidelist dt br, &.readme_list dt br {
          display: block;
        }
      }
      div.price_section dl.price_order dt br {
        display: block;
      }
      dl {
        &.voice_list dt br, &.question_list dt br, &.jiko_list dl dt br {
          display: block;
        }
        &.slidelist dd.entry {
          margin: 10px 0;
        }
      }
      div.price_section dl.price_order dd.entry {
        margin: 10px 0;
      }
      dl {
        &.profile_list dd.entry, &.readme_list dd.entry, &.question_list dd.entry, &.flow_list dd.entry, &.voice_list dd.entry, &.privacy_list dd, &.jiko_list dd, &.nail_list dd {
          margin: 10px 0;
        }
        &.slidelist dd {
          &.entry h3 {
            width: 100%;
          }
          p {
            &.s, &.m {
              width: 100%;
            }
          }
          &.entry {
            h3 {
              font-size: 13px;
              line-height: 100%;
            }
            li.last.style {
              line-height: 20px;
              text-indent: -1em;
              padding-left: 1.5em;
            }
          }
          div.slidelist_img {
            display: none;
          }
        }
      }
    }
    &#sidemenu {
      width: 100%;
      position: static;
      border-top: 2px dotted #9e9e9f;
      margin-top: 20px;
      padding: 20px 10px 0 10px;
      h2 {
        margin-bottom: 0;
        &.shourei {
	        border: none;
        }
        img {
	        border-radius: 8px 8px 0 0;
        }
        &.click {
	        margin-bottom: 15px;
	        border-bottom: 1px solid #666;
	        border-radius: 8px;
        }
        
      }
      ul {
        &.banaarea li {
          margin-bottom: 15px;
          
          &.ekiten {
	          display: none;
          }
          
          &.blogarea {
	          
	          ul.blog_list {
		          
		          li {
			          padding: 5px 10px;
		          }
		          
	          }
	          
          }
          
        }
        &.sidemenu_list {
          display: block;
          li {
            line-height: 20px;
            font-size: 16px;
            padding: 10px 0;
            &.utsu {
              letter-spacing: normal;
            }
            a {
              background-position: 10px center;
              padding: 0 0 0 22px;
              display: block;
            }
          }
        }
      }
    }
    &.fb_iframe_widget, &.bana, &.ekiten_frame {
      display: none;
    }
    &#footer {
      &.info {
        display: none;
      }
      &.menu {
        a#logo, ul {
          display: none;
        }
      }
      &.info .inner {
        background: none;
        padding: 5px 0 0 0;
      }
      &.menu {
        background: none;
        padding: 0;
        p {
          position: static;
          height: 40px;
          line-height: 40px;
          font-size: 10px;
          text-align: center;
          background: #231815;
        }
        div {
          &.tel.sp {
            margin-top: 5px;
            display: none !important;
            a {
              display: block;
              line-height: 0;
            }
            p {
              height: auto;
              line-height: 22px;
              font-size: 12px;
              text-align: left;
              background: none;
            }
          }
          &.sp_menu {
            color: #231815;
            font-size: 12px;
            padding: 10px;
            text-align: center;
          }
        }
      }
    }
    &#wrapper {
      div {
        &.readme {
          padding: 20px 10px;
          h2 {
            line-height: 0;
          }
          ul {
            width: 100%;
            li {
              margin-bottom: 20px;
              display: list-item;
              position: relative;
              &.last {
                margin-bottom: 0;
              }
              img {
                width: 50%;
                height: auto;
                max-width: 200px;
                max-height: 200px;
                margin: 0;
              }
              p {
                height: 100px;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 50%;
                margin: auto;
                padding-left: 10px;
                text-align: left;
                font-size: 16px;
                line-height: 25px;
                br {
                  display: none;
                }
              }
            }
          }
        }
        &.summary {
          padding: 20px 10px;
          p {
            text-align: left;
            line-height: 25px;
          }
        }
      }
      dl.readme_list {
        margin: 20px 0 0 0;
        padding: 0 10px;
        dd.entry {
          p.l {
            width: 100%;
            line-height: 25px;
          }
          div {
            &.imgps06, &.imgps07 {
              display: none;
            }
          }
        }
      }
      div#readme02.readme ul li {
        p {
          height: 50px;
        }
        &:nth-of-type(2) p {
          height: 75px;
        }
      }
      dl.readme_list dd.entry {
        p.r {
          margin-left: 0;
        }
        ul {
          &.r {
            margin-left: 0;
          }
          margin: 10px 0;
        }
        p {
          &.r span {
            width: 1em;
            display: inline-block;
            color: #fff;
            position: relative;
            &:before {
              content: "下";
              width: 1em;
              display: block;
              color: #231815;
              position: absolute;
              top: 0;
              left: 0;
            }
          }
          line-height: 25px;
        }
        div.imgps07 {
          position: static;
          margin: 10px 0;
        }
      }
      div {
        &.reserve {
          margin: 20px 0;
          h2 {
            padding: 0 10px;
            p {
              line-height: 25px;
            }
          }
          dl.reserve_list {
            padding: 0 10px;
            dt {
              min-height: 40px;
              line-height: 20px;
              font-size: 14px;
              padding: 10px 10px 10px 45px;
              position: relative;
              &:before {
                content: "";
                width: 30px;
                height: 30px;
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                left: 10px;
              }
              &.re01:before {
                background: url(../images/num01.gif) no-repeat;
                background-size: 30px 30px;
              }
              &.re02:before {
                background: url(../images/num02.gif) no-repeat;
                background-size: 30px 30px;
              }
              &.re03:before {
                background: url(../images/num03.gif) no-repeat;
                background-size: 30px 30px;
              }
              img {
                display: none;
              }
            }
            dd {
              p {
                br {
                  display: none;
                }
                width: 100%;
              }
              margin: 15px 0;
              padding: 0;
              img {
                position: static;
                margin: 0 auto 10px auto;
                width: auto;
                display: block;
              }
            }
          }
        }
        &.readme div.how {
          margin-top: 10px;
          img {
            height: auto;
            position: static;
            margin-bottom: 10px;
          }
          p {
            margin-left: 0;
            line-height: 25px;
          }
        }
      }
      dl.flow_list {
        padding: 0 10px;
        margin-bottom: 20px;
        dt {
          padding: 10px 10px 10px 45px;
          font-size: 16px;
          position: relative;
          &:before {
            content: "";
            width: 30px;
            height: 30px;
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 10px;
            margin: auto;
          }
          &#flow01:before {
            background: url(../images/num01.gif) no-repeat;
            background-size: 30px 30px;
          }
          &#flow02:before {
            background: url(../images/num02.gif) no-repeat;
            background-size: 30px 30px;
          }
          &#flow03:before {
            background: url(../images/num03.gif) no-repeat;
            background-size: 30px 30px;
          }
          &#flow04:before {
            background: url(../images/num04.gif) no-repeat;
            background-size: 30px 30px;
          }
          &#flow05:before {
            background: url(../images/num05.gif) no-repeat;
            background-size: 30px 30px;
          }
          &#flow06:before {
            background: url(../images/num06.gif) no-repeat;
            background-size: 30px 30px;
          }
          img {
            display: none;
          }
        }
        dd.entry {
          padding: 0;
          margin: 15px 0;
          p {
            width: 100%;
          }
          img {
            position: static;
            width: auto;
            margin: 0 auto 10px auto;
            display: block;
          }
        }
      }
      div {
        &.pricelist ul {
          padding: 15px 10px;
          li {
            width: 100%;
            margin: 0 0 15px 0;
            display: list-item;
            position: relative;
            &.last {
              margin-bottom: 0;
            }
            img {
              width: 40%;
            }
            h2 {
              position: absolute;
              top: 10px;
              left: 40%;
              font-size: 14px;
              line-height: 18px;
              padding: 0 10px;
              margin: 0;
              border-bottom: none;
              br {
                display: none;
              }
            }
            &.last h2 br {
              display: block;
            }
            div {
              &.price_txt {
                display: none;
              }
              &.price {
                position: absolute;
                top: 49px;
                left: 40%;
                margin-bottom: 0;
                padding: 0 10px;
                p span {
                  font-size: 12px;
                  line-height: 100%;
                  padding: 3px 5px;
                  margin-right: 5px;
                }
              }
            }
          }
        }
        &.price_section h2 p span {
          font-size: 12px;
          line-height: 100%;
          padding: 3px 5px;
          margin-right: 5px;
        }
        &.pricelist ul li div.price p {
          font-size: 14px;
          margin: 0;
          + p {
            margin-top: 3px;
          }
          span + span {
            font-size: 10px;
          }
        }
        &.price_section h2 p span + span {
          font-size: 10px;
        }
        &.pricelist {
          ul li a {
            margin-top: 15px;
          }
          p {
            padding-bottom: 15px;
          }
        }
        &.price_section {
          margin: 20px 0;
          h2 {
            padding: 0 10px;
            font-size: 0;
            p {
              font-size: 14px;
              display: inline-block;
              + p {
                margin-left: 0;
              }
              span + span {
                margin-right: 0;
              }
            }
          }
          ul.symptom {
            width: 100%;
            margin: 15px 0;
            li {
              background-position: 10px;
              min-height: 40px;
              height: auto;
              line-height: 20px;
              padding: 10px 10px 10px 40px;
            }
          }
          div.imgposi02 {
            display: none;
          }
          dl.price_order {
            margin: 20px 0;
            padding: 0 10px;
            dd.entry ul {
              &.w_style {
                height: auto;
                li {
                  float: none;
                }
              }
              &.h_style {
                margin: 10px 0;
                li {
                  padding-left: 1.5em;
                  text-indent: -1em;
                  margin: 10px 0;
                  &.start {
                    margin-top: 0;
                  }
                  &.last {
                    margin-bottom: 0;
                  }
                  p {
                    padding-right: 1.5em;
                    text-indent: 0;
                  }
                }
              }
            }
          }
        }
        &.profile {
          padding: 15px 10px;
          h2 {
            width: 100%;
            height: 180px;
            background-size: 50%;
            margin: 0 0 10px 0;
            padding: 10px 10px 10px 53%;
            float: none;
            ul {
              position: static;
              margin-top: 10px;
            }
            span.sp {
              display: none !important;
            }
          }
        }
      }
      dl.profile_list {
        padding: 0 10px;
        dd {
          &.history {
            padding: 0;
            margin-bottom: 15px;
            p {
	          height: 70px;
              line-height: 18px !important;
              padding: 10px 5px 10px 80px;
              
              &.images {
	              height: auto;
	              
	              img {
		              position: static;
		              height: auto;
		              margin-top: 20px;
	              }
	              
	              span.caption {
		              position: static;
		              margin-top: 10px;
	              }
	              
	              &.rugby {
		              height: auto;
	              }
	              
              }
              
            }
          }
          &.entry p span {
            display: block;
            line-height: 100%;
            margin: 0;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
      div.profile_photo {
        margin: 0;
        padding: 30px 10px 15px 10px;
        ul {
          width: 100%;
          margin: 15px 0;
          display: block;
          li {
            width: auto;
            display: list-item;
            position: relative;
            text-align: left;
            margin: 0 0 15px 0;
            &.last {
              margin-bottom: 0;
            }
          }
          &.person li img {
            width: 40%;
            border-radius: 50%;
            margin: 0;
          }
          li {
            p {
              height: 40px;
              line-height: 20px;
              font-size: 15px;
              position: absolute;
              top: 0;
              bottom: 0;
              margin: auto;
              left: 40%;
              padding-left: 15px;
            }
            &.last br {
              display: none;
            }
          }
        }
        
        &.under {
	        
	        h2#profile04 {
			    padding-left: 0;
			    line-height: 30px;
	        }
	        
	        ul.recommend {
		        width: 100%;
		        
		        li {
			        width: 100%;
			        padding: 0;
			        
			        a {
				        display: block;
			        }
			        
			        p {
				        position: static;
				        padding-left: 0;
			        }
			        
			        img {
				        position: static;
			        }
			        
			        h3 {
				        margin: 10px 0;
			        }
			        
		        }
		        
	        }

        }
        
        hr {
          width: 100%;
          padding: 0;
        }
        ul.award {
          width: 100%;
          margin-bottom: 0;
		  padding: 0 10px;
          li {
	          width: 50%;
	          padding: 5px;
	          margin: 0 0 15px 0;
	          vertical-align: top;
            img {
              width: 100%;
              height: auto;
            }
            &.last, img {
              margin-bottom: 0;
            }
            
            p {
	            position: static;
	            height: auto;
	            padding-left: 0;
	            margin-top: 10px;
            }
            
          }
        }
      }
      dl {
        &.profile_list dd.entry {
          div {
            &.imgposi03, &.imgposi04, &.imgposi05 {
              display: none;
            }
          }
          p {
            &.l {
              width: 100%;
              padding: 0;
            }
            &.r {
              margin: 0.5em 0;
              padding: 0;
            }
            line-height: 25px;
          }
        }
        &.voice_list {
          margin-bottom: 20px;
          padding: 0 10px;
          dd.entry {
            padding: 0;
            img {
              &.bdr01, &.bdr02 {
                width: 50%;
                margin: 0 10px 10px 0;
                float: left;
              }
            }
            hr {
              &.l, &.r {
                margin: 10px 0;
              }
            }
            p {
              &.l, &.r {
                width: 100%;
              }
            }
            hr {
              &.l.p, &.r.p {
                width: 100%;
              }
            }
            div {
              &.voice_r img, &.voice_l img {
                width: 100%;
              }
              &.voice_r, &.voice_l {
                position: static;
                margin-bottom: 15px;
              }
            }
            p.r, hr.r.p {
              margin-left: 0;
            }
          }
        }
        &.question_list {
          margin-bottom: 20px;
          padding: 0 10px;
          dt {
            padding-left: 40px;
            &::before {
              content: "";
              width: 25px;
              height: 25px;
              display: block;
              background: url(../images/q.png) no-repeat;
              background-size: 25px 25px;
              top: 0;
              bottom: 0;
              left: 10px;
              margin: auto;
            }
          }
          dd {
            background-position: 10px top;
            background-size: 25px 25px;
            &.entry p.sick {
              font-size: 14px;
              padding: 0 0 10px 40px;
            }
          }
          div.sick {
            margin-left: 0;
          }
          dd.entry {
            dl {
              dt {
                font-size: 14px;
                line-height: 30px;
                min-height: 30px;
              }
              dd {
                line-height: 20px;
                padding-bottom: 15px;
              }
              &.short {
                width: 100%;
                display: block;
              }
            }
            p {
              &.attention {
                margin: 0;
              }
              padding-left: 40px;
            }
          }
        }
        &.contact_list {
          padding: 0 10px;
          margin: 0;
          h2 {
            line-height: 0;
            p {
              line-height: 25px;
              text-align: center;
              margin: 10px 0 20px 0;
            }
          }
          
          h3 {
	          margin: 0;
          }
          
          ul.contact_type {
	          margin: 0;
	          line-height: 1;
	          
	          li {
		          width: 100%;
		          height: 50px;
		          line-height: 48px;
		          margin-bottom: 15px;
		          margin-right: 0 !important;
	          }
	          
          }
          
        }
        &.privacy_list h2 p {
          line-height: 25px;
          text-align: center;
          margin: 10px 0 20px 0;
        }
        &.contact_list {
          dt {
            margin: 0;
          }
          dd {
            margin: 0;
            padding-bottom: 15px;
            input {
	            width: 100% !important;
	            &.mfp_element_checkbox {
		            width: auto !important;
	            }
            }
            &:last-of-type {
              padding-bottom: 0;
            }
            input.postfield, ol li select {
              width: 100% !important;
            }
            input {
              &#mfp_element_6, &#mfp_element_7 {
                width: 100% !important;
              }
            }
            ol li {
              margin: 0;
              padding-bottom: 15px;
              &.last {
                padding-bottom: 0;
              }
              span {
                width: 100%;
                line-height: 25px;
                margin-right: 0;
                display: block;
              }
            }
          }
        }
      }
    }
    &.mfp_err {
      padding: 0 !important;
      background-image: none !important;
      line-height: 100% !important;
    }
    &#wrapper {
      dl.contact_list dd:last-of-type div.mfp_err {
        text-align: center;
      }
      div.bt {
        margin: 15px 0;
        padding: 15px 10px 0 10px;
        button {
          width: 100%;
          margin-bottom: 0;
          &.submit {
            margin-top: 10px;
          }
        }
      }
    }
    &.contact_list {
      min-height: 0 !important;
      padding: 0 10px;
      margin-bottom: 20px !important;
    }
    &#wrapper {
      div.contact_list {
        h2 {
          margin-bottom: 20px;
          line-height: 0;
        }
        p {
          line-height: 25px;
        }
      }
      a.contact_bt {
        width: 100%;
        margin: 20px 0;
      }
      div {
        &.access {
          padding: 0 10px;
          margin-bottom: 20px;
          h2 {
            font-size: 16px;
            line-height: 40px;
            margin-bottom: 15px;
          }
          iframe {
	          width: 100%;
	          height: 400px;
          }
          div#map {
	          margin-top: 15px;
          }
          div#map, a.bt {
            margin-bottom: 15px;
          }
          p {
            line-height: 25px;
            text-indent: -1em;
            padding-left: 1em;
          }
        }
        &.anime_map {
          margin-top: 15px;
        }
        &.sitemap {
          padding: 0 10px;
        }
        &.sitemap_list {
          width: 100%;
          display: block;
          + div.sitemap_list {
            margin-left: 0;
          }
          div.menu {
            margin-bottom: 15px;
            h2 {
              font-size: 18px;
              line-height: 20px;
              padding-bottom: 5px;
              margin-bottom: 10px;
            }
            ul {
              list-style: none;
              padding-left: 0;
              li {
                height: auto;
                font-size: 14px;
                line-height: 20px;
                margin-bottom: 0;
                a {
                  height: auto;
                  min-height: 20px;
                  line-height: 20px;
                  display: block;
                  padding: 10px 0 10px 20px;
                  position: relative;
                  &:before {
                    content: "└";
                    height: 20px;
                    margin-right: 5px;
                    display: block;
                    color: #888;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    margin: auto;
                  }
                  &:hover {
                    border-width: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  /*!header h1*/
  /*!main*/
  dl.privacy_list {
    padding: 0 10px;
  }
  div {
    &.contents {
      padding: 0 10px;
      margin-top: 20px;
      div.none {
        h1 {
          position: relative;
          font-size: 20px;
          line-height: 25px;
          color: #5c070c;
          padding-left: 30px;
          margin-bottom: 15px;
          span {
            width: 25px;
            height: 25px;
            border-radius: 25px;
            line-height: 25px;
            color: #FFF;
            background: #5c070c;
            font-size: 18px;
            text-align: center;
            position: absolute;
            top: 0;
            left: 0;
            margin-top: 0;
          }
        }
        p {
          line-height: 25px;
          br {
            display: none;
          }
        }
        span {
          width: 100%;
          line-height: 40px;
          font-size: 14px;
          font-weight: bold;
          text-align: center;
          color: #FFF;
          background: #76161b;
          display: block;
          margin-top: 15px;
        }
      }
    }
    &#container {
      &.shourei, &.blog {
        height: 85px;
        background: none;
      }
    }
    &#wrapper.shourei {
	    margin-top: 15px;
      h1 {
        line-height: 27px;
        padding: 10px;
        font-size: 23px;
        border-radius: 0;
      }
      div.main_img img, p.update {
        padding: 0 10px;
      }
      div {
        &.main_img {
          width: 100%;
          margin: 15px 0;
        }
        &.topic {
          margin-bottom: 0;
          padding: 0 10px;
          p {
            font-size: 16px;
            line-height: 25px;
            margin: 0.5em 0;
            padding: 0;
            br {
              display: none;
            }
            strong {
              padding-bottom: 0;
            }
          }
        }
      }
      h2 {
        margin: 15px 0;
        padding: 10px;
        line-height: 25px;
        
        &.patient {
	        line-height: 20px;
	        margin: 0 10px 15px 10px;
        }
        
      }
      
      div.comment {
	      margin-right: 10px;
	      margin-left: 10px;
      }
      
      dl.voice_list.page {
	      
	      dt {
		      padding-right: 45px;
	      }
	      
	      dd {
		      
		      div.voice_wrapper {
			      width: 100%;
			      
			      p {
				      
				      &.bdr01 {
					      margin-bottom: 20px;
					      padding: 0;
				      }
				      
			      }
			      
		      }
		      
	      }
	      
      }
      
      a.chiebukuro {
	      padding: 10px;
	      
	      &:before {
		      width: 50%;
	      }
	      
	      img {
		      width: 40%;
		      height: auto;
		      display: inline-block;
	      }
	      
	      span {
		      width: 50%;
		      line-height: 25px;
		      vertical-align: top;
	      }
	      
      }
      
      ul {
        margin: 15px 0;
        &.sports_pic {
	        padding: 0 10px;
	        li {
		        &.last {
			        display: inline-block;
		        }
	        }
        }
        li {
          width: 100%;
          background-position: 10px;
          min-height: 40px;
          height: auto;
          line-height: 20px;
          padding: 10px 10px 10px 40px;
          &.last {
            display: none;
          }
        }
      }
      div.help {
        margin: 15px 0 20px 0;
        padding: 0 10px;
        h3 {
          font-size: 25px !important;
          line-height: 35px;
          margin-bottom: 10px;
          padding: 0;
          min-height: 0;
        }
        p {
          font-size: 20px;
          line-height: 28px;
          margin: 0;
          padding: 0;
          text-align: left;
        }
      }
      h3 {
        margin: 10px 10px 0 10px;
        padding: 0 10px;
        font-size: 15px;
        line-height: 30px !important;
        &.num01, &.num02, &.num03, &.num04 {
          height: 90px;
          background-position: 10px center;
          padding-left: 105px;
          padding-right: 10px;
          font-size: 20px;
          display: table-cell;
          vertical-align: middle;
        }
      }
      ul.note {
        margin: 10px;
        li.start {
          padding-bottom: 10px;
        }
      }
      p {
        padding: 0 10px;
        img {
          float: none;
          margin: 0;
          margin-bottom: 15px;
        }
      }
      ul.figure li {
        width: 100%;
        margin: 0 0 10px 0;
        padding: 0 10px;
        display: block;
        &.last {
          margin: 0;
        }
      }
    }
  }
  ul.postlist.latest-articles {
    margin: 0 0 20px 0 !important;
  }
  
  
  div#wrapper {
	  
	 div.archive {
		 padding-right: 15px;
		 padding-left: 15px;

		 ul {
			 width: calc((100% - 15px)/2);
			 padding: 0;
			 
			 +ul {
				 margin-left: 15px;
			 }
			 
		 }
		 
	 }
	  
    ul.postlist.latest-articles li {
      width: 100%;
      height: auto;
      margin-bottom: 15px;
      padding-bottom: 15px;
      a {
        width: 100%;
        height: 100%;
        margin-bottom: 15px;
        padding-bottom: 15px;
        padding: 0 10px;
      }
      div.thumb {
	      width: 160px;
	      height: 110px;
	      overflow: hidden;
	      position: absolute;
	      left: 10px;
      }
      img {
        width: 160px;
        height: auto;
        position: static;
      }
      h2 {
        font-size: 16px;
        line-height: 22px;
        min-height: 65px;
        padding-top: 10px;
      }
      p.day {
        font-size: 13px;
      }
      h2, p.day {
        margin-left: 170px;
      }
      div {
        &.article {
          display: none;
        }
        &.bt {
          width: 100%;
          height: 30px;
          line-height: 26px;
          margin: auto;
          position: static;
        }
      }
    }
    div {
      &.wp-pagenavi {
        padding: 0 10px;
        margin-bottom: 15px;
        text-align: center;
      }
      &.shourei_list {
        h1 {
          padding: 0 10px;
          margin-bottom: 10px;
          line-height: 25px;
        }
        div {
          &.date p {
            padding: 0 10px;
          }
          &.article {
            margin: 15px 0 0 0;
            min-height: 0;
          }
        }
        margin: 0;
        min-height: 0;
      }
    }
  }
  img.attachment-730_500.wp-post-image {
    margin-bottom: 15px;
  }
  div {
    &#wrapper {
      div.shourei_list div.article {
        p {
          font-size: 16px;
          padding: 0 10px;
          + p {
            margin: 0.5em 0;
          }
          iframe {
            width: 100%;
          }
        }
        ul.interview_photo {
          padding: 0 10px;
          margin-bottom: 30px;
          li {
            width: 50%;
            padding: 10px;
            margin: 0;
            margin-right: 0 !important;
            vertical-align: top;
            h3 {
              width: auto !important;
            }
          }
        }
      }
      &.blog {
        div.tel {
          width: 100%;
          margin: 0 0 20px 0;
        }
        ul.kiji {
          width: 100%;
          height: auto;
          margin: 15px 0;
          li {
            width: 100%;
            height: 150px;
            padding-right: 0 !important;
            padding-left: 0 !important;
            float: none !important;
            margin-bottom: 15px;
            a {
              &:before {
                background: url(../images/next_arrow.gif) no-repeat !important;
                left: 10px !important;
                right: auto !important;
              }
              width: 100%;
              height: 150px;
              padding: 0 10px;
              position: relative;
            }
            + li {
              margin-left: 0;
            }
            img {
              width: 150px;
              height: 150px;
              position: static !important;
            }
            p {
              width: auto;
              position: absolute;
              top: 10px;
              left: 170px;
              padding-right: 10px;
              margin: 0 !important;
            }
            span {
              position: absolute;
              left: 170px;
              bottom: 50px;
              margin: 0 !important;
            }
            div.bt {
              width: auto;
              margin-left: 0 !important;
              margin-right: 10px;
              bottom: 10px;
              left: 170px;
              right: 0;
              position: absolute;
              line-height: 26px;
              height: 30px;
            }
          }
        }
      }
      div {
        &.shourei_list div.article {
          h3 {
            font-size: 17px;
            display: block;
            padding: 0 10px;
            margin: 15px 10px 5px 10px;
          }
          ul {
            padding: 0 10px;
            li {
              font-size: 16px;
              p {
                margin: 0.5em 1em !important;
              }
            }
          }
          em {
            font-size: 13px;
            line-height: 20px;
            display: block;
            margin-top: 5px;
            text-indent: -1em;
            padding-left: 1em;
          }
        }
        &.nail_list {
          margin-bottom: 20px;
          h2 {
            padding: 0 10px;
          }
          ul {
            width: 100%;
            margin: 15px 0;
            li {
              background-position: 10px;
              min-height: 40px;
              height: auto;
              line-height: 20px;
              padding: 10px 10px 10px 40px;
            }
          }
          div.imgps09 {
            display: none;
          }
        }
        &.nail_example {
          margin: 15px 0;
          padding: 10px;
          ul.nail_example {
            width: 100%;
            display: block;
            margin: 10px 0 0 0;
            li {
              width: 50%;
              display: inline-block;
              margin: 0 0 10px 0;
              padding: 0 10px;
              &:after, &:before {
                content: none !important;
              }
              img {
                border-radius: 200px;
                margin-bottom: 5px;
              }
            }
          }
        }
      }
      dl.nail_list {
        margin-bottom: 20px;
        padding: 0 10px;
        dd ul li {
          width: 80%;
          margin: 0 auto;
          display: block;
          &.start {
            margin-bottom: 35px;
            &:after {
              bottom: -45px;
              left: 0;
              right: 0;
              margin: auto;
              top: auto;
              transform: rotate(-45deg);
              -moz-transform: rotate(-45deg);
              -webkit-transform: rotate(90deg);
              -o-transform: rotate(-45deg);
              -ms-transform: rotate(-45deg);
              z-index: 1;
              background-size: 40px;
            }
          }
        }
      }
      div {
        &.nail_example {
          h3 {
            font-size: 17px;
            line-height: 100%;
          }
          div {
            &.oniduka, &.phenol {
              margin: 30px 0 15px 0;
              padding: 0 10px;
            }
            position: static !important;
            width: 100% !important;
            min-height: 0 !important;
            &.bad {
              margin-top: 30px;
            }
            h2 {
              position: relative;
              font-size: 16px !important;
              &:before {
                left: -15px !important;
              }
              &:after {
                bottom: -24px !important;
                left: 0 !important;
                right: 0 !important;
              }
            }
          }
        }
        &.nail_really {
          height: auto;
          background: none;
          padding: 0 10px;
          margin-bottom: 15px;
          p {
            width: 100%;
            &.sp {
              margin: 0;
              line-height: 0;
              position: relative;
              &:before {
                content: "全国1,000院以上の医療機関で同等の効果がある器具が使用されています。";
                position: absolute;
                top: 20px;
                left: 0;
                color: #FFF;
                width: 50%;
                line-height: 20px;
                padding: 5px;
                display: block;
                font-size: 13px;
                background: #5c070c;
              }
            }
          }
        }
        &.nail_price {
          margin: 0 10px 15px 10px;
          padding: 10px;
          border-width: 3px;
          h2 {
            margin: 0 0 10px 0;
            display: block;
          }
          ul li {
            width: 100%;
            &.start {
              margin-bottom: 10px;
            }
          }
        }
      }
      dl.nail_list {
        p span {
          margin: 0;
          display: block;
        }
        dd {
          min-height: 0 !important;
        }
        &.s dd.entry img {
          display: none;
        }
        dd.entry p.r {
          margin: 0;
        }
      }
    }
    &#container div.nail.inner h1 {
      background: rgba(0, 0, 0, 0.4);
      position: absolute !important;
      padding: 10px;
      bottom: 84px;
      width: 100%;
    }
    &#wrapper {
      div.jiko_list {
        margin-bottom: 20px;
        h2 {
          padding: 0 10px;
          margin-bottom: 15px;
          span {
            margin-top: 5px;
          }
        }
        ul {
          width: 100%;
          margin: 15px 0;
        }
        div.imgps08 {
          display: none;
        }
        ul li h2 {
          font-size: 20px !important;
        }
      }
      dl {
        &.question_list h2, &.flow_list h2 {
          font-size: 20px !important;
        }
      }
      div.jiko_list ul li {
        background-position: 10px;
        min-height: 40px;
        height: auto;
        line-height: 20px;
        padding: 10px 10px 10px 40px;
      }
      dl {
        &.jiko_list {
          padding: 0 10px;
          margin-bottom: 20px;
          dd.entry {
            p.l {
              margin-left: 0;
            }
            div.jikolist_img {
              display: none;
            }
          }
        }
        &.flow_list {
          dd.entry p a {
            display: block;
          }
          div.attention {
            padding: 10px;
          }
        }
      }
    }
  }
  dl#jiko03.question_list ul li {
    padding-left: 40px !important;
    text-indent: -1em;
  }
  div#wrapper.shourei {
    ul.shoujou li {
      font-size: 15px;
      padding: 0 15px;
      text-indent: -1em;
      padding-left: 2em;
      line-height: 20px;
      min-height: 0;
      margin: 10px 0;
      span {
        text-indent: 0;
        padding-left: 0;
      }
    }
    h4 {
      padding: 0 10px;
    }
    ul {
      &.shoujou li {
        &.last {
          display: list-item;
        }
        span.note {
          margin: 10px 0 0 0;
        }
      }
      &.note li.last {
        display: block;
      }
    }
    h5 {
      padding: 0 10px;
    }
    ul.note {
      padding: 10px;
      li {
        min-height: 0;
        padding: 0;
        text-indent: -1em;
        padding-left: 1em;
        &.start {
          padding-bottom: 0;
        }
        &.last {
          padding-top: 0;
        }
      }
    }
    span.note {
      margin: 10px 0 0 0;
    }
    ol li {
      font-size: 15px;
      text-indent: 0;
      padding-left: 0;
      b {
        font-size: 14px;
        position: static;
        text-indent: 0;
        background: #5c070c;
        color: #fff;
        text-align: center;
        font-weight: normal;
        margin-bottom: 5px;
      }
      span {
        height: auto;
        line-height: 20px;
        padding-left: 0;
        text-indent: 0;
        margin: 5px 0 10px 0;
      }
    }
  }
  table#mfp_confirm_table tr {
    th {
      width: 100px !important;
      padding: 5px !important;
    }
    td {
      padding: 5px !important;
    }
  }
  
  div #wrapper {
	  
	  &.voice {
		  margin-top: 20px;
		  
		  div.comment {
			  margin-right: 15px;
			  margin-left: 15px;
		  }
		  
		  dl.voice_list {
			  
			  dt {
				  padding-right: 45px;
			  }
			  
			  dd {
				  
				  div.voice_wrapper {
					  width: 100%;
				  }
				  
				  p {
					  
					  &.bdr01 {
						  margin-bottom: 20px;
					  }
					  
				  }
				  
				  
			  }
			  
		  }
		  
	  }
	  
  }
  
  div.stage {
	  margin: 0 10px;
  }
  
  ul.meniere_li {
	  
	  li {
		  display: block !important;
	  }
	  
  }
  
  div#wrapper.shourei div.imgps07.meniere ul li {
	  width: 100%;
	  display: list-item;
	  padding: 10px;
  }
  
  div#wrapper.shourei ul.w_style {
	  padding: 0 10px;
	  
	  li {
		  width: 100%;
		  padding: 0 10px;
		  margin: 0;
	  }
  }
  
  div.nekoze_price {
	  margin: 15px 10px;
  }
  
  ul.nekoze {
	  padding: 0 10px;
	  
	  li {
	    width: 100% !important;
	    height: 160px !important;
	    padding: 0 0 0 40% !important;
	    overflow: hidden;
	    
	    &.last {
		    display: block !important;
	    }
	    
	    img {
		    width: 40%;
		    height: auto;
	    }
	    
	    p {
		    padding: 0 !important;
	    }
	    
	  }
	  
  }
  div#wrapper ul.list.shourei li {
	width: 100%;
	height: auto;
	font-size: 15px;
	padding: 15px;
	border-bottom: 2px dotted #9e9e9f;
	position: relative;
	
	a {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		display: block;
	}
	
	span {
		font-size: 13px;
		line-height: 1;
		display: block;
		margin: 10px 0;
	}
	
	p {
		width: 100%;
		height: 30px;
		font-size: 15px;
		line-height: 26px;
		text-align: center;
		color: #5c070c;
		border: 2px solid #5c070c;
		text-align: center;
	}
	
  }
  
}

  div.none {
    h1 {
      position: relative;
      font-size: 20px;
      line-height: 25px;
      color: #5c070c;
      padding-left: 30px;
      margin-bottom: 15px;
      span {
        width: 25px;
        height: 25px;
        border-radius: 25px;
        line-height: 25px;
        color: #FFF;
        background: #5c070c;
        font-size: 18px;
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
        margin-top: 0;
      }
    }
    p {
      line-height: 25px;
      br {
        display: none;
      }
    }
    span {
      width: 100%;
      line-height: 40px;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      color: #FFF;
      background: #76161b;
      display: block;
      margin-top: 15px;
    }
  }

/* !!------------------------------------ */
/* !! うつ追加コンテンツ */
/* !!------------------------------------ */

#wrapper.shourei {
	.wrap {
		h2 {
			width: calc(100% - 30px);
			font-size: 24px;
			color: #3e3a39;
			font-family: "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
			background: none;
			border-top: 1px solid #808080;
			border-bottom: 1px solid #808080;
			margin: 0 0 30px auto;
			padding-left: 50px;
			position: relative;
			&:before {
				content: "";
				width: 63px;
				height: 72px;
				display: block;
				vertical-align: middle;
				background: url(/images/ttl_icon.png);
				background-size: 63px 72px;
				position: absolute;
				top: -11px;
				left: -30px;
			}
		}
		ul {
			padding: 0 30px;
			margin: 0 0 40px 0;
			li {
				width: 100%;
				height: 50px;
				font-size: 18px;
				line-height: 50px;
				padding-left: 0;
				border: none;
				text-align: center;
				background: #f7c8ce;
				margin-bottom: 15px;
				border-radius: 5px;
				&.start {
					border-top: none;
				}
				&.last {
					position: static;
					background: #f7c8ce;
					margin-bottom: 0;
				}
			}
		}
		p {
			width: calc(100% - 60px);
			padding: 20px;
			margin: 0 auto;
			padding: 15px;
			text-align: justify;
			background: #efefef;
			border-radius: 10px;
			margin-bottom: 40px;
			span {
				margin-top: 10px;
				padding-left: 1em;
			}
		}
	}
	ul.none {
		margin: 0;
		li {
			width: 100%;
			height: auto;
			line-height: 25px;
			padding-left: 0;
			font-weight: normal;
			border-bottom: none;
			list-style: disc inside;
			background: none;
			&:first-child {
				border-top: none;
			}
			&:last-child {
				position: static;
			}
		}
		&.draw {
			display: flex;
			flex-wrap: wrap;
			margin: 30px 0;
			li {
				width: calc((100% - 20px)/2);
				padding: 20px;
				border-radius: 10px;
				list-style: none;
				background: #faf7f2;
				border: 1px solid #5a3c1e;
				text-align: justify;
				
				h4 {
					font-size: 14px;
					font-weight: bold;
					color: #231815;
					margin: 0;
				}
				p {
					margin: 0;
				}
				&:first-child {
					margin-right: 20px;
					background: #eae6de;
				}
				&:last-child {
					width: 100%;
					margin-top: 20px;
					background: #dfefec;
					position: relative;
					&:before {
						content: "";
						width: 60px;
						height: 80px;
						display: block;
						background: url(/images/arrow03.png) no-repeat center center;
						background-size: 60px 80px;
						position: absolute;
						top: -60px;
						left: calc(50% - 210px);
					}
					&:after {
						content: "";
						width: 60px;
						height: 80px;
						display: block;
						background: url(/images/arrow03.png) no-repeat center center;
						background-size: 60px 80px;
						position: absolute;
						top: -60px;
						right: calc(50% - 210px);
					}
				}
			}
		}
	}
}

/* !!------------------------------------ */
/* !! セロトニン追加コンテンツ */
/* !!------------------------------------ */

#wrapper.shourei {
	.serotonin_wrap {
		position: relative;
		overflow: hidden;
		img {
			float: right;
			margin: 0 0 20px 20px;
		}
	}
	.stress {
		margin-top: 70px;
		h2 {
			color: #fff;
			background: #ec6c00;
			padding-left: 83px;
			position: relative;
			border-radius: 25px;
			&:before {
				content: "";
				width: 33px;
				height: 99px;
				display: block;
				margin-right: 10px;
				vertical-align: middle;
				background: url(/images/serotonin_img04.png) no-repeat center center;
				background-size: 33px 99px;
				position: absolute;
				bottom: 10px;
				left: 30px;
				margin: auto;
			}
		}
		ul {
			display: flex;
			flex-wrap: wrap;
			li {
				width: calc((100% - 40px)/3) !important;
				height: auto;
				border: none !important;
				list-style: none;
				padding: 15px !important;
				background: #e3edfc;
				margin-right: 20px;
				border-radius: 10px;
				&:first-child {
					background: #fff0f0;
					
				}
				&:last-child {
					margin-right: 0;
					position: static;
					background: #e7fff8;
				}
			}
		}
		h3 {
			font-size: 14px;
			color: #231815;
			margin: 0 0 10px 0;
			border: none;
			text-align: center;
			border-bottom: 1px solid #231815;
			padding: 0;
		}
	}
	a.link {
		width: 100%;
		height: 50px;
		line-height: 50px;
		text-align: center;
		background: #5c070c;
		color: #fff;
		border-radius: 25px;
		display: block;
		margin: 20px 0 40px 0;
	}
}


/* !!------------------------------------ */
/* !! むち打ち追加コンテンツ */
/* !!------------------------------------ */

#wrapper.shourei {
	.wrap {
		img.muchiuchi {
			margin: 0 0 20px 20px;
			float: none;
			float: right;
		}
		.muchiuchi.flow {
			li {
				width: calc((100% - 100px)/3);
				height: auto;
				display: inline-block;
				vertical-align: top;
				margin-right: 50px;
				margin-bottom: 30px;
				font-size: 14px;
				font-weight: normal;
				line-height: 22px;
				background: none;
				position: relative;
				img {
					display: block;
					margin: 0 auto 20px auto;
					border: 1px solid #808080;
					+br {
						display: none;
					}
				}
				h3 {
					font-size: 16px;
					line-height: 1;
					font-weight: bold;
					text-align: left;
					color: #231815;
					border: none;
					border-bottom: 1px solid #231815;
					margin: 0 0 10px 0;
					padding: 0 0 10px 0;
				}
				p {
					width: 100%;
					font-size: 14px;
					line-height: 22px;
					text-align: justify;
					background: none;
					padding: 0;
					margin: 0;
				}
				&:nth-child(3n) {
					margin-right: 0;
				}
				&:before {
					content: "";
					width: 32px;
					height: 20px;
					display: block;
					background: url(/images/muchiuchi_arrow01.png) no-repeat center center;
					background-size: 32px 20px;
					position: absolute;
					top: 75px;
					right: -42px;
				}
				&:nth-child(3) {
					&:before {
						width: 24px;
						height: 300px;
						background: url(/images/muchiuchi_arrow02.png) no-repeat center center;
						background-size: 24px 300px;
						right: -32px;
					}
				}
				&:nth-child(n + 4) {
					margin-bottom: 0;
					&:before {
						transform: rotate(180deg);
						-webkit-transform: rotate(180deg);
						-moz-transform: rotate(180deg);
						-o-transform: rotate(180deg);
						-ms-transform: rotate(180deg);
					}
				}
				&.last {
					&:before {
						content: none;
					}
				}
				span {
					width: 50px;
					height: 50px;
					display: block;
					font-size: 24px;
					font-family: "ヒラギノ明朝 ProN W3", "HiraMinProN-W3", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
					line-height: 50px;
					color: #fff;
					background: #99a93d;
					border-radius: 50%;
					position: absolute;
					top: -10px;
					left: -10px;
				}
			}
		}
		.attention {
			background: #ffc34b;
			padding: 30px;
			position: relative;
			border-radius: 10px;
			margin-bottom: 40px;
			span {
				width: 540px;
				display: block;
				font-size: 20px;
				line-height: 30px;
				font-weight: bold;
				color: #603813;
			}
			img {
				position: absolute;
				right: 30px;
				bottom: 0;
			}
		}
		.muchiuchi.question {
			padding: 0 30px;
			dt {
				font-size: 18px;
				font-weight: bold;
				line-height: 50px;
				color: #231815;
				padding-left: 20px;
				list-style: none;
				background: #dff1f7;
				border-radius: 5px;
			}
			dd {
				font-size: 16px;
				font-weight: normal;
				line-height: 26px;
				color: #231815;
				margin-left: 25px;
				padding: 20px 0;
				text-indent: -1.5em;
				padding-left: 1.5em;
				list-style: none;
				&:before {
					content: none;
				}
			}
		}
	}
}

div#wrapper.shourei p img.wp-image-2719.fn {
	float: none;
	margin: 0;
}
div#wrapper.shourei p img.wp-image-2729.fr , div#wrapper.shourei p img.wp-image-2728.fr {
	float: right;
	margin: 0 0 20px 20px;
}

/* !!------------------------------------ */
/* !! 自律神経失調症追加コンテンツ */
/* !!------------------------------------ */

div#wrapper.shourei p img.wp-image-2718.fr {
	float: right;
	margin: 0 0 20px 20px;
}

@media screen and (max-width: 767px) {

//sp変更
div#container {
	padding-top: 0 !important;
}
div#container.home {
	margin-top: 0 !important;
	//height: 380px;
	//padding-top: 80px;
	div.flexslider , div.main.inner {
		height: 100% !important;
		ul.slides {
			height: 100% !important;
			li {
				height: 100% !important;
			}
		}
	}
	div.flexslider {
		display: none !important;
	}
	div#header h1 {
		padding: 10px !important;
	}
}
div#header {
	position: absolute;
	a#splogo {
		display: block;
	}
	
	ul.spmenu_wrap {
		height: 60px;
		padding: 0 4vw;
		margin-bottom: 4vw;
		li {
			height: 60px;
			display: inline-block;
			vertical-align: top;
			position: relative;
			
			&.mail {
				width: 80px;
				border: 1px solid #fff;
				a {
					position: absolute;
					top: 0;
					left: 0;
					z-index: 1;
				}
				p {
					width: 100%;
					font-size: 10px;
					line-height: 1;
					text-align: center;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translateY(-50%) translateX(-50%);
					-webkit-transform: translateY(-50%) translateX(-50%);
					-moz-transform: translateY(-50%) translateX(-50%);
					-o-transform: translateY(-50%) translateX(-50%);
					-ms-transform: translateY(-50%) translateX(-50%);
					span {
						font-size: 8px;
						display: block;
						margin-top: 1vw;
						&:before {
							content: "";
							width: 50px;
							height: 1px;
							display: block;
							margin: 0 auto;
							margin-bottom: 1vw;
							background: #fff;
						}
					}
					img {
						width: 24px;
						height: auto;
						display: block;
						margin: 0 auto;
						margin-bottom: 1vw;
					}
				}
			}
			&.tel {
				width: calc(100% - (140px + 8vw));
				margin: 0 4vw;
				background : -moz-linear-gradient(50% 100% 90deg,rgba(255, 147, 30, 1) 0%,rgba(255, 194, 85, 1) 100%);
				background : -webkit-linear-gradient(90deg, rgba(255, 147, 30, 1) 0%, rgba(255, 194, 85, 1) 100%);
				background : -webkit-gradient(linear,50% 100% ,50% 0% ,color-stop(0,rgba(255, 147, 30, 1) ),color-stop(1,rgba(255, 194, 85, 1) ));
				background : -o-linear-gradient(90deg, rgba(255, 147, 30, 1) 0%, rgba(255, 194, 85, 1) 100%);
				background : -ms-linear-gradient(90deg, rgba(255, 147, 30, 1) 0%, rgba(255, 194, 85, 1) 100%);
				-ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF931E', endColorstr='#FFC255' ,GradientType=0)";
				background : linear-gradient(0deg, rgba(255, 147, 30, 1) 0%, rgba(255, 194, 85, 1) 100%);
				border-radius : 5px;
				-moz-border-radius : 5px;
				-webkit-border-radius : 5px;
				img {
					max-width: calc(100% - 2vw);
					height: auto;
					max-height: 60px;
					padding: 1vw;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translateY(-50%) translateX(-50%);
					-webkit-transform: translateY(-50%) translateX(-50%);
					-moz-transform: translateY(-50%) translateX(-50%);
					-o-transform: translateY(-50%) translateX(-50%);
					-ms-transform: translateY(-50%) translateX(-50%);
				}
			}
			&.bt {
				width: 60px;
				height: 60px;
				margin-right: 0;
				font-size: 8px;
				line-height: 1;
				text-align: center;
				padding-top: 2vw;
				cursor: pointer;
				i {
					width: 100%;
					font-size: 8px;
					display: block;
					font-style: normal;
					position: absolute;
					bottom: 2vw;
				}
				p {
					width: 100%;
					height: 100%;
					border: 2px solid #fff;
					position: absolute;
					top: 0;
					span {
						width: 30px;
						height: 2px;
						display: block;
						background: #fff;
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						margin: auto;
						&:before {
							content: "";
							width: 100%;
							height: 2px;
							display: block;
							background: #fff;
							position: absolute;
							top: -8px;
						}
						&:after {
							content: "";
							width: 100%;
							height: 2px;
							display: block;
							background: #fff;
							position: absolute;
							bottom: -8px;
						}
					}
				}
			}
			a {
				width: 100%;
				height: 100%;
				display: block;
			}
			img {
				width: auto;
				height: auto;
			}
		}
	}
	div.sp_tel {
		display: block !important;
	}
	
}
ul.sp_sub.sp {
	height: auto;
	background: #fff;
	position: absolute;
	top: 50px;
	z-index: 11;
	padding: 10px 15px;
	&.fixed {
		position: fixed;
		top: 0;
	}
	li {
		width: calc(100% / 5);
		height: auto;
		font-size: 3.5vw;
		line-height: 1;
		color: #fff;
		display: inline-block;
		position: relative;
		padding: 0 2vw;
		color: #231815;
		text-align: center;
		a {
			width: 100%;
			height: auto;
			display: block;
		}
		img {
			display: block;
			margin-bottom: 5px;
		}
	}
}
ul.ft_menu {
	position: fixed;
	bottom: 0;
	background: #fff;
	padding: 10px 15px;
	z-index: 2;
	li {
		display: inline-block;
		&.tel {
			width: 62%;
		}
		&.contact {
			width: 36%;
			margin-left: 2%;
		}
	}
}
div.sptel {
	background : -moz-linear-gradient(50% 0% -90deg,rgba(93, 7, 12, 1) 0%,rgba(0, 0, 0, 0.9) 100%);
	background : -webkit-linear-gradient(-90deg, rgba(93, 7, 12, 1) 0%, rgba(0, 0, 0, 0.9) 100%);
	background : -webkit-gradient(linear,50% 0% ,50% 100% ,color-stop(0,rgba(93, 7, 12, 1) ),color-stop(1,rgba(0, 0, 0, 0.9) ));
	background : -o-linear-gradient(-90deg, rgba(93, 7, 12, 1) 0%, rgba(0, 0, 0, 0.9) 100%);
	background : -ms-linear-gradient(-90deg, rgba(93, 7, 12, 1) 0%, rgba(0, 0, 0, 0.9) 100%);
	-ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#5D070C', endColorstr='#000000' ,GradientType=0)";
	background : linear-gradient(180deg, rgba(93, 7, 12, 1) 0%, rgba(0, 0, 0, 0.9) 100%);
	position: relative;
	padding: 4vw;
	a {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
	}
	h2 {
		font-size: 16px;
		line-height: 1;
		font-weight: bold;
		color: #fff;
		text-align: center;
		margin-bottom: 4vw;
		+p {
			height: 24px;
			font-size: 14px;
			font-weight: bold;
			line-height: 24px;
			text-align: center;
			border-radius: 4px 4px 0 0;
			background : -moz-linear-gradient(50% 100% 90deg,rgba(255, 147, 30, 1) 0%,rgba(255, 194, 85, 1) 100%);
			background : -webkit-linear-gradient(90deg, rgba(255, 147, 30, 1) 0%, rgba(255, 194, 85, 1) 100%);
			background : -webkit-gradient(linear,50% 100% ,50% 0% ,color-stop(0,rgba(255, 147, 30, 1) ),color-stop(1,rgba(255, 194, 85, 1) ));
			background : -o-linear-gradient(90deg, rgba(255, 147, 30, 1) 0%, rgba(255, 194, 85, 1) 100%);
			background : -ms-linear-gradient(90deg, rgba(255, 147, 30, 1) 0%, rgba(255, 194, 85, 1) 100%);
			-ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF931E', endColorstr='#FFC255' ,GradientType=0)";
			background : linear-gradient(0deg, rgba(255, 147, 30, 1) 0%, rgba(255, 194, 85, 1) 100%);
			border-radius : 4px 4px 0px 0px;
			-moz-border-radius : 4px 4px 0px 0px;
			-webkit-border-radius : 4px 4px 0px 0px;
		}
	}
	div.tel_area {
		padding: 4vw;
		background : -moz-linear-gradient(50% 100% 90deg,rgba(255, 147, 30, 1) 0%,rgba(255, 194, 85, 1) 100%);
		background : -webkit-linear-gradient(90deg, rgba(255, 147, 30, 1) 0%, rgba(255, 194, 85, 1) 100%);
		background : -webkit-gradient(linear,50% 100% ,50% 0% ,color-stop(0,rgba(255, 147, 30, 1) ),color-stop(1,rgba(255, 194, 85, 1) ));
		background : -o-linear-gradient(90deg, rgba(255, 147, 30, 1) 0%, rgba(255, 194, 85, 1) 100%);
		background : -ms-linear-gradient(90deg, rgba(255, 147, 30, 1) 0%, rgba(255, 194, 85, 1) 100%);
		-ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF931E', endColorstr='#FFC255' ,GradientType=0)";
		background : linear-gradient(0deg, rgba(255, 147, 30, 1) 0%, rgba(255, 194, 85, 1) 100%);
		img {
			width: 100%;
			height: auto;
			display: block;
			margin-bottom: 4vw;
		}
		span {
			font-size: 12px;
			text-align: center;
			line-height: 20px;
			color: #fff;
			background: #231815;
			border-radius: 10px;
			margin-bottom: 1vw;
			display: block;
		}
		p {
			font-size: 12px;
			font-weight: bold;
			line-height: 20px;
			+p {
				margin-bottom: 4vw;
			}
		}
	}
	+hr {
		margin-top: 25px;
	}
}

h2#shourei {
	padding-top: 170px;
	margin-top: -170px;
}
h2#column {
	padding-top: 170px;
	margin-top: -170px;
}

div.ninja_onebutton {
	margin: 4vw 0 8vw;
	padding: 0 4vw;
	text-align: center;
	div {
		div {
			margin: 0 !important;
			transform: scale(0.9);
			-webkit-transform: scale(0.9);
			-moz-transform: scale(0.9);
			-o-transform: scale(0.9);
			-ms-transform: scale(0.9);
			&.facebook_like_box_count {
				height: 40px;
			}
		}
	}
}

/* !!------------------------------------ */
/* !! うつ追加コンテンツ */
/* !!------------------------------------ */

#wrapper.shourei {
	.wrap {
		h2 {
			width: calc(100% - 40px);
			font-size: 18px;
			margin-right: 10px;
			&:before {
				top: 0;
				left: -20px;
				bottom: 0;
				margin: auto;
			}
			&:first-of-type {
				margin-top: 20px;
			}
		}
		ul {
			padding: 0 20px;
			li {
				height: auto;
				font-size: 16px;
				line-height: 26px;
				padding: 10px 20px;
				&.last {
					background: #f7c8ce;
					display: block;
					padding: 10px 20px;
				}
			}
		}
		p {
			width: calc(100% - 40px);
		}
	}
	ul.none {
		padding: 0 10px;
		li {
			min-height: 0;
			padding: 0;
		}
		&.draw {
			margin: 15px 0;
			li {
				padding: 15px;
				h4 , p {
					padding: 0;
				}
				&:last-child {
					display: block;
					&:before {
						height: 50px;
						top: -35px;
						left: calc(50% - 110px);
						background-position: center top;
					}
					&:after {
						height: 50px;
						top: -35px;
						right: calc(50% - 110px);
						background-position: center top;
					}
				}
			}
		}
	}
}

/* !!------------------------------------ */
/* !! セロトニン追加コンテンツ */
/* !!------------------------------------ */

#wrapper.shourei {
	.serotonin_wrap {
		padding: 0 10px;
		img {
			float: none;
			margin: 0 0 20px 0;
		}
		p {
			padding: 0;
		}
	}
	.stress {
		h2 {
			border-radius: 0;
			
		}
		ul {
			display: block;
			li {
				width: calc(100% - 20px) !important;
				margin: 0 auto 10px auto !important;
				&:last-child {
					display: block;
				}
			}
		}
	}
	a.link {
		font-size: 3.8vw;
	}
}
div#wrapper.shourei p img.wp-image-2719.fn {
	float: none;
	margin: 0 0 15px 0;
}
div#wrapper.shourei p img.wp-image-2729.fr , div#wrapper.shourei p img.wp-image-2728.fr {
	float: none;
	margin: 0 0 15px 0;
}
div#wrapper.shourei p img.wp-image-2728.fr {
	width: 50%;
	display: block;
	margin: 0 auto 15px auto;
}

/* !!------------------------------------ */
/* !! むち打ち追加コンテンツ */
/* !!------------------------------------ */

#wrapper.shourei {
	.wrap {
		img.muchiuchi {
			margin: 0px 0px 20px 0;
			float: none;
		}
		.muchiuchi.flow {
			li {
				width: 100%;
				margin-bottom: 50px;
				display: list-item;
				img {
					width: 100%;
					height: auto;
				}
				&:nth-child(3) {
					&:before {
						width: 32px;
						height: 20px;
						background: url(/images/muchiuchi_arrow01.png) no-repeat center center;
						background-size: 32px 20px;
						right: 0;
					}
				}
				&:nth-child(n + 4) {
					margin-bottom: 50px;
					&:before {
						transform: rotate(90deg);
						-webkit-transform: rotate(90deg);
						-ms-transform: rotate(90deg);
						-o-transform: rotate(90deg);
						-ms-transform: rotate(90deg);
					}
				}
				&:before {
					top: auto;
					bottom: -32px;
					right: 0;
					left: 0;
					margin: auto;
					transform: rotate(90deg);
					-webkit-transform: rotate(90deg);
					-ms-transform: rotate(90deg);
					-o-transform: rotate(90deg);
					-ms-transform: rotate(90deg);
				}
			}
		}
		.attention {
			width: calc(100% - 40px);
			margin: 0 auto;
			padding: 15px;
			padding-bottom: 0;
			margin-bottom: 40px;
			span {
				font-size: 14px;
				line-height: 24px;
				width: 100%;
			}
			img {
				width: 103px;
				height: auto;
				position: static;
				margin: 0 auto;
				display: block;
			}
		}
		.muchiuchi.question {
			dt {
				height: auto;
				line-height: 26px;
				padding: 10px;
				text-indent: -1em;
				padding-left: 2em;
			}
			dd {
				padding: 10px ;
			}
		}
	}
}

//spメインビジュアル　キャッチ
div#container .flexslider div.main.inner h1 {
	width: 100%;
	padding: 0 2% !important;
	margin: 0;
	top: 2%;
	bottom: auto;
	img {
		width: 100%;
	}
}
div#container div.inner .sp.catch {
	width: 100%;
	top: 50%;
	left: 0;
}
div#container div.inner .catch.top {
	width: 70%;
	bottom: 18%;
	right: 2%;
}
div#container div.inner .catch.bottom {
	bottom: 3%;
	padding: 0 2%;
}

.sp_mv {
	position: relative;
}

.sp.mv {
	height: auto !important;
/*
	position: absolute;
	bottom: 0;
*/
}

.flex-control-nav {
	display: none;
}










/* !!------------------------------------ */
/* !! はらべ鍼灸整体院の根本改善とは */

div.movie_area {
	padding-bottom: 40px;
	span {
		text-align: center;
		padding-right: 0;
	}
	h2 {
		padding: 20px 0 !important;
		line-height: 1.3;
		&:before {
			width: 63px;
			height: 30px;
			position: static;
			display: block;
			margin: 0 auto 10px auto;
			background-size: 63px 30px;
		}
	}
	iframe {
		width: calc(100% - 40px);
	}
}
.line_bana {
	margin-top: 15px;
}

/* !!------------------------------------ */
/* !! 自律神経失調症追加コンテンツ */
/* !!------------------------------------ */

div#wrapper.shourei p img.wp-image-2718.fr {
	float: none;
	margin: 0 0 15px 0;
}


}//media


/* !!------------------------------------ */
/* !! 201901001 追加 */
@mixin sp {
  @media screen and (max-width: 767px) {
    @content;
  }
}
@mixin pc {
  @media screen and (min-width: 768px) {
    @content;
  }
}

$px : 0.267vw;

//!!院長挨拶
.greetingbox03{
	@include sp{
		h3{
			border: 1px solid #000;
			padding: 10px;
			text-align: center;
			border-radius: 8px;
			font-size: 18px;
			letter-spacing: .1em;
			margin: 10px
		}
		padding: 10px;
		img{
			width: 80%;
			display: block;
			margin: 0 auto;
			margin-bottom: 15px;
		}
		p{
			margin-bottom: 1em;
		}
	}//@sp
}

.profile_photo{
	margin-bottom: 0 !important;
}

.media{
	margin-top: 0 !important;
	margin-bottom: 0 !important;
	@include sp{
		padding-bottom: 20px !important;
	}//@sp
	@include pc{
		padding-bottom: 30px !important;
		margin-top: 0 !important;	
	}//@pc
	.radio{
		margin-bottom: 0 !important;
	}
}

.section01{
	@include sp{
		margin-bottom: 0 !important;
		
	}//@sp
}
.media_bana._2{
	height: 140px;
    background: url(/images/media_bg.jpg) no-repeat center center;
    background-size: 730px 140px;
    font-weight: normal;
    margin: 10px;
    @include pc{
    	margin: 0 !important;	
    }//@pc
    margin-bottom: 15px;
    margin-top: 0 !important;
    position: relative;
	
    @include sp{
    	background-size: 100% 100%;
    	height: auto;
    	h2{
	    	background: none !important;
	    	padding: 10px 0 !important;
	    	margin: 0 !important;
    	}
    }//@sp
    span{
	    @include sp{
		    background: none !important;
	    	height: auto;	
	    	position: relative !important;
	    	top: 0 !important;left: 0 !important;
	    	transform: none !important;
	    	font-size: 11*$px !important;
	    	white-space: nowrap;
	    }//@sp
		color: #fff;
		font-size: 14px;
		line-height: 18px;
		display: block;
		margin-bottom: 6px;
		strong{
		    display: block !important;
		    line-height: 1 !important;
		    margin-top: 10px;
		    @include sp{
		    	font-size: 24px !important;	
		    }//@sp
	    }
    }
    
}


.case-lead{
	@include sp{
		font-size: 16px;
		line-height: 44px;
		text-align: center;	
		hr{
			margin: 10px 0;
		}
		h3 {
			background: #fff !important;
			color: #5c070c !important;
			padding: 10px 0 !important;
			white-space: nowrap;
			strong{
				font-size: 17*$px;
				line-height: 1.4;
				background: linear-gradient(transparent 50%, yellow 50%) !important;
			}		
		}
			
	}//@sp
}



